import { Body1, H2, Label } from '@/components/Typography';
import { theme } from '@/styles/mui-theme';
import { RpmStatusTypes, type AccountsMonitoringDeviceType } from '@/types/remotePatientMonitoring';
import type { ChipProps } from '@mui/material';
import { Chip, Popover, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { useContext, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ChipsList from '@/components/ChipsList';
import PatientDetailContext from '@/context/patientDetailContext';
import styled from '@/styles';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const maxNamesToShow = 2;

const ChipColumn = ({ label, value, ...props }: { label: ReactNode; value: ReactNode } & ChipProps) => {
  return (
    <Stack flexDirection="row" gap={1} alignItems="center" justifyContent="center">
      <Body1>{label}</Body1>
      <Chip sx={{ fontWeight: '400' }} variant={'outlined'} label={value} color={'primary'} {...props} />
    </Stack>
  );
};

type Props = {
  children?: ReactNode;
  devices: AccountsMonitoringDeviceType[] | [];
};

const ButtonMoreContainer = styled('div', {
  margin: 'auto !important',
  '& button': {
    marginLeft: '8px'
  },
  '.MuiButton-iconSizeSmall': {
    margin: '0px !important'
  }
});

const RpmWorkflowToolbar = ({ children, devices }: Props) => {
  const { patientInfo } = useContext(PatientDetailContext);

  const [devicesSuppliedAnchorEl, setDevicesSuppliedAnchorEl] = useState(null);
  const [showMoreAssignedDevices, setShowMoreAssignedDevices] = useState(false);

  const handleAssignedDevicesPopoverClick = event => {
    setDevicesSuppliedAnchorEl(event.currentTarget);
    setShowMoreAssignedDevices(true);
  };

  const handleAssignedDevicesPopoverClose = () => {
    setDevicesSuppliedAnchorEl(null);
    setShowMoreAssignedDevices(false);
  };

  function formatString(text: string) {
    const words = text.split('_');
    const formattedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const formattedString = formattedWords.join(' ');

    return formattedString;
  }

  return (
    <Stack sx={{ background: theme.palette.background.paper }} p={4} borderRadius={4} spacing={2}>
      {patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED && (
        <Alert
          status={'error'}
          description={`${getAccountUserFullName(patientInfo?.user)} unenrolled from the program on ${formatDate(
            patientInfo?.rpmUnenrolledAt
          )}`}
        />
      )}
      <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent="space-between">
        <H2>Patient Setup</H2>
        <Stack>
          <Stack direction="row" columnGap={2}>
            {patientInfo?.rpmStatus && (
              <ChipColumn
                label={<Label>Status</Label>}
                color={patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED ? 'error' : 'primary'}
                value={
                  <Body1 color={patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED ? 'error' : 'default'}>
                    {formatString(patientInfo?.rpmStatus) +
                      '' +
                      (patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED
                        ? ' on ' + formatDate(patientInfo?.rpmUnenrolledAt)
                        : patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED
                        ? ' on ' + formatDate(patientInfo?.rpmEnrolledAt)
                        : '')}
                  </Body1>
                }
              />
            )}
            {patientInfo?.rpmApprovalStatus && (
              <ChipColumn label={<Label>Approval Status</Label>} value={<Body1>{formatString(patientInfo?.rpmApprovalStatus)}</Body1>} />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <Body1>Devices Supplied:</Body1>
          {devices?.length > 0 ? (
            <Stack direction="row" spacing={1} alignItems={'center'}>
              <Stack direction="row" spacing={1}>
                {devices.map(
                  ({ monitoringDevice }, index) =>
                    index < maxNamesToShow && (
                      <div key={`${monitoringDevice?.name}-${index}`}>
                        {monitoringDevice?.name && (
                          <Chip size="medium" variant={'outlined'} label={monitoringDevice?.name} style={{ marginRight: '0px' }} />
                        )}
                      </div>
                    )
                )}

                {devices.length > maxNamesToShow && (
                  <ButtonMoreContainer>
                    <Button
                      variant="text"
                      label={`${devices.length - maxNamesToShow}+`}
                      onClick={handleAssignedDevicesPopoverClick}
                      endIcon={showMoreAssignedDevices ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    />
                  </ButtonMoreContainer>
                )}
              </Stack>
              <Popover open={showMoreAssignedDevices} anchorEl={devicesSuppliedAnchorEl} onClose={handleAssignedDevicesPopoverClose}>
                <Stack direction="row" spacing={1} sx={{ p: 2 }}>
                  <ChipsList
                    items={devices?.map(({ monitoringDevice }) => monitoringDevice?.name)}
                    maxItemsToShow={maxNamesToShow}
                    sx={{ marginRight: '4px' }}
                  />
                  <Button variant="text" onClick={handleAssignedDevicesPopoverClose}>
                    Close
                  </Button>
                </Stack>
              </Popover>
            </Stack>
          ) : (
            <Chip size="medium" variant={'outlined'} label={'Not Assigned'} style={{ marginRight: '4px' }} />
          )}
        </Stack>
        <Stack spacing={2}>{children}</Stack>
      </Stack>
    </Stack>
  );
};

export default RpmWorkflowToolbar;
