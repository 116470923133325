import Button from '@/components/Button';
import ChipsList from '@/components/ChipsList';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ScheduledReportsListDialog from '@/components/Dialogs/ScheduledReportsListDialog';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import Link from '@/elements/Link';
import type { CreateScheduledReportResponse } from '@/graphql/administration';
import { CREATE_SCHEDULED_REPORT, GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import { GET_TASKS_BY_FILTER, GET_TASKS_BY_FILTER_TO_EXPORT } from '@/graphql/taskEncounter';
import type { ClientOrganizationObject, ClientStoreObject, GetClientOrganizationResponse } from '@/types/administration';
import { ScheduledReportTypeEnum } from '@/types/administration';
import type { TaskType } from '@/types/graphql';
import type { GridTypes } from '@/types/grid';
import type { GetTasksByFilterResponse } from '@/types/tasks';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getFirstDateOfCurrentMonth, toDateOrNull } from '@/util/date';
import { formatDate, formatDateIgnoreTZ } from '@/util/format';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import type { ApolloError } from '@apollo/client/errors';
import { Box, Chip, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { MomentInput } from 'moment';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

type FilterOptions = {
  organization?: string;
  orgStore?: string;
  performedOnStartDate?: MomentInput;
  performedOnEndDate?: MomentInput;
  status: string[];
};

export enum BillingReportType {
  Full = 'Full',
  Limited = 'Limited'
}

const Billing = () => {
  const [today] = useState(new Date());
  const [getTasks, { loading: getTasksLoading, called: getTasksCalled, error: getTasksError, data: getTasksData }] =
    useLazyQuery<GetTasksByFilterResponse>(GET_TASKS_BY_FILTER);
  const [getExport] = useLazyQuery(GET_TASKS_BY_FILTER_TO_EXPORT);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [billingExportReportData, setBillingExportReportData] = useState<TaskType[]>([]);
  const [billingReportLoading, setBillingReportLoading] = useState<boolean>(false);
  const [fetchDataButtonVisible, setFetchDataButtonVisible] = useState<boolean>(true);
  const [exportBillingReportLoading, setExportBillingReportLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    performedOnStartDate: getFirstDateOfCurrentMonth(today),
    performedOnEndDate: today,
    status: ['COMPLETED', 'ATTEMPTED']
  });
  const currentUser = useReactiveVar(currentLoggedUserVar);
  //TODO: This will not work if dataset is > pagesize. We should consider retrieving filters via separate api calls
  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });
  const [createScheduledReport, { loading: createScheduledReportLoading }] =
    useMutation<CreateScheduledReportResponse>(CREATE_SCHEDULED_REPORT);

  const defaults: { id: string; name: string }[] = [{ id: 'all', name: 'All' }];
  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);
  const [startDate, setStartDate] = useState<MomentInput>(getFirstDateOfCurrentMonth(today));
  const [endDate, setEndDate] = useState<MomentInput>(today);

  const [selectedOrgId, setSelectedOrgId] = useState<string>('all');
  const [selectedClientStoreId, setSelectedClientStoreId] = useState<string>('');

  const [totalBonusAmount, setTotalBonusAmount] = useState<number>(0);
  const [totalBilledAmount, setTotalBilledAmount] = useState<number>(0);

  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const callGetTasks = async () => {
    setBillingReportLoading(true);
    await getTasks({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        isExport: false,
        relyingPartyAdminId: currentUser?.id,
        filterOptions: {
          ...filterOptions,
          performedOnStartDate: formatDate(filterOptions.performedOnStartDate, 'YYYY-MM-DD'),
          performedOnEndDate: formatDate(filterOptions.performedOnEndDate, 'YYYY-MM-DD')
        }
      }
    }).catch((error: ApolloError) => {
      onTasksDataError(error);
    });
    setBillingReportLoading(false);
  };

  const exportDataToCsv = async () => {
    setExportBillingReportLoading(true);
    await getExport({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        isExport: true,
        filterOptions: {
          ...filterOptions,
          performedOnStartDate: formatDate(filterOptions.performedOnStartDate, 'YYYY-MM-DD'),
          performedOnEndDate: formatDate(filterOptions.performedOnEndDate, 'YYYY-MM-DD')
        }
      }
    })
      .then(res => {
        setExportBillingReportLoading(false);
        setBillingExportReportData(res?.data?.tasksByFilter?.tasks);
      })
      .catch(() => {
        setExportBillingReportLoading(false);
      });
  };

  const onRunReportClicked = () => {
    setFetchDataButtonVisible(true);
    callGetTasks().catch(error => {
      console.error(error);
    });
  };

  const handleScheduleReport = async () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Schedule Report',
      message: 'This will schedule to generate a report for the selected options. Are you sure you want to proceed?',
      onAgree: async () => {
        await scheduleBillingReport();
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      maxWidth: 'md'
    });
  };

  const scheduleBillingReport = async () => {
    try {
      const response = await createScheduledReport({
        variables: {
          reportType: ScheduledReportTypeEnum.MTM_BILLING_REPORT,
          scheduledReportInput: {
            filterOrganizationId: selectedOrgId === 'all' ? undefined : selectedOrgId,
            filterStartDate: formatDate(filterOptions.performedOnStartDate, 'YYYY-MM-DD'),
            filterEndDate: formatDate(filterOptions.performedOnEndDate, 'YYYY-MM-DD')
          }
        }
      });

      if (response?.data?.createScheduledReport?.status === 'Success') {
        enqueueSnackbar('Report scheduled successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to schedule report', { variant: 'error' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Failed to schedule report', { variant: 'error' });
    }
  };

  const onFetchDataClicked = () => {
    setFetchDataButtonVisible(false);
    exportDataToCsv().catch(error => {
      console.error(error);
    });
  };

  const onViewReportsClicked = () => {
    const modal = showModal(ScheduledReportsListDialog, {
      hideDialog: () => {
        modal.hide();
      },
      reportType: ScheduledReportTypeEnum.MTM_BILLING_REPORT,
      title: 'Scheduled Reports'
    });
  };

  const getBilledForName = (firstName: string, lastName: string, reportType: BillingReportType) => {
    let billingNameStr = 'Not Provided';
    if (reportType === BillingReportType.Limited) {
      let firstNameInitial = '';
      let lastNameInitial = '';
      if (firstName) {
        firstNameInitial = firstName[0].toUpperCase();
      }
      if (lastName) {
        lastNameInitial = lastName[0].toUpperCase();
      }
      billingNameStr = firstNameInitial + lastNameInitial || 'Not Provided';
    } else {
      billingNameStr = firstName + ' ' + lastName || 'Not Provided';
    }
    return billingNameStr;
  };

  const newBillingReport = (reportType: BillingReportType) => {
    try {
      const newBillingReportData = billingExportReportData.map(
        data => ({
          Name: getBilledForName(String(data?.account?.user?.firstName), String(data?.account?.user?.lastName), reportType),
          Type: data?.type || 'Not Provided',
          Status: data?.status || 'Not Provided',
          Billed: data?.billedAmount || '0',
          Bonus: data?.bonusAmount || '0',
          Resolution: data?.modeOfResolution || 'Not Provided',
          'Wrap Up Status': data?.wrapUpStatus || 'Not Provided',
          'Performed By': data?.completedByAdmin || 'Not Provided',
          'Performed On': data?.performedOn || 'Not Provided',
          Organization: data?.account?.clientOrgs?.map(org => org.name) || 'Not Provided',
          Location: data?.account?.clientStores?.map(loc => loc.name) || 'Not Provided'
        }),
        []
      );

      return newBillingReportData;
    } catch (error) {
      console.error(error);
    }
  };

  const formatUserName = () => {
    return [
      { label: 'Name', key: 'Name' },
      { label: 'Type', key: 'Type' },
      { label: 'Status', key: 'Status' },
      { label: 'Billed ($)', key: 'Billed' },
      { label: 'Bonus ($)', key: 'Bonus' },
      { label: 'Resolution', key: 'Resolution' },
      { label: 'Wrap Up Status', key: 'Wrap Up Status' },
      { label: 'Performed By', key: 'Performed By' },
      { label: 'Performed On', key: 'Performed On' },
      { label: 'Organization', key: 'Organization' },
      { label: 'Location', key: 'Location' }
    ];
  };

  const createCsvFileName = (reportType: BillingReportType) =>
    `Billing Report ${reportType} -- ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.csv`;

  const headers = formatUserName();

  const columns: GridColDef<GridTypes>[] = useMemo(
    () => [
      {
        field: 'account-fullname',
        headerName: 'Name',
        sortable: true,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: params => {
          return (
            <Link to={`/providers/${params.row.account.truentityId}/manage`}>
              {String(params.row.account.user.firstName) + ' ' + String(params.row.account.user.lastName)}
            </Link>
          );
        }
      },
      {
        field: 'type',
        headerName: 'Type',
        sortable: true,
        valueGetter: params => params.row.type,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        renderCell: params => <Box sx={{ textTransform: 'capitalize' }}>{params.row.status.toLowerCase()}</Box>,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1
      },
      {
        field: 'billedAmount',
        headerName: 'Billed',
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1,
        renderCell: params => <Box>{`$ ${params.row.billedAmount || 0}`}</Box>
      },
      {
        field: 'bonusAmount',
        headerName: 'Bonus',
        sortable: true,
        headerAlign: 'center',
        align: 'center',
        maxWidth: 120,
        flex: 1,
        renderCell: params => <Box>{`$ ${params.row.bonusAmount || 0}`}</Box>
      },
      {
        field: 'modeOfResolution',
        headerName: 'Resolution',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.modeOfResolution?.replace(/([A-Z])/g, ' $1').trim(),
        align: 'left'
      },
      {
        field: 'wrapUpStatus',
        headerName: 'Wrap Up Status',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.wrapUpStatus?.replace(/([A-Z])/g, ' $1').trim(),
        align: 'left'
      },
      {
        field: 'completedByAdmin',
        headerName: 'Performed By',
        sortable: true,
        align: 'left',
        flex: 1
      },
      {
        field: 'performedOn',
        headerName: 'Performed On',
        sortable: true,
        flex: 1,
        type: 'date',
        valueGetter: params => toDateOrNull(params.value),
        renderCell: params => (
          <Link to={`patients/${params.row.account.truentityId}/details/medications/tasks/list`}>
            {formatDateIgnoreTZ(params.row.performedOn, 'MMM DD, YYYY')}
          </Link>
        ),
        align: 'left'
      },
      {
        field: 'organization',
        headerName: 'Organization',
        sortable: true,
        renderCell: params => <ChipsList size="small" items={params.row?.account.clientOrgs.map(v => v.name)} />,
        flex: 2,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'store',
        headerName: 'Location',
        sortable: true,
        renderCell: params => (
          <ChipsList variant="outlined" size="small" items={params.row?.account.clientStores.map(store => store.name)} />
        ),
        flex: 2,
        headerAlign: 'center',
        align: 'center'
      }
    ],
    []
  );

  const isFiltersApplied = () => {
    let filterApplied = false;

    if (selectedOrgId !== 'all') {
      filterApplied = true;
    }

    if (selectedClientStoreId !== '') {
      filterApplied = true;
    }

    const firstDayOfCurrentMonth = getFirstDateOfCurrentMonth(today);
    const lastDateOfPreviousMonth = today;

    if (startDate?.valueOf() !== firstDayOfCurrentMonth?.valueOf()) {
      filterApplied = true;
    }

    if (endDate?.valueOf() !== lastDateOfPreviousMonth?.valueOf()) {
      filterApplied = true;
    }
    return filterApplied;
  };

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations([...defaults, ...organizationData.clientOrganizations.clientOrganizations]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    setFilteredClientStores([]);
    setSelectedClientStoreId('');

    const org = clientOrganizations?.find(o => o.id === selectedOrgId);
    if (org && org.clientStores?.length > 0) {
      setFilteredClientStores([...defaults, ...org.clientStores]);
      setSelectedClientStoreId('all');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrgId]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (getTasksData && getTasksData?.tasksByFilter?.tasks) {
      setTasks(getTasksData?.tasksByFilter?.tasks);
      setRowCount(getTasksData?.tasksByFilter?.meta?.totalCount);

      setTotalBilledAmount(getTasksData.tasksByFilter?.totalBilledAmount || 0);
      setTotalBonusAmount(getTasksData.tasksByFilter?.totalBonusAmount || 0);
    } else {
      setTotalBilledAmount(0);
      setTotalBonusAmount(0);
    }
  }, [getTasksData]);

  useEffect(() => {
    if (getTasksError) {
      onTasksDataError(getTasksError);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getTasksError]);

  const onTasksDataError = (error: ApolloError) => {
    enqueueSnackbar('Failed to retrieve tasks', { variant: 'error' });
    console.error(error);
    setTasks([]);
  };
  useEffect(() => {
    setFilterOptions({
      organization: selectedOrgId?.length > 0 && selectedOrgId !== 'all' ? selectedOrgId : undefined,
      orgStore: selectedClientStoreId?.length > 0 && selectedClientStoreId !== 'all' ? selectedClientStoreId : undefined,
      performedOnStartDate: startDate || undefined,
      performedOnEndDate: endDate || undefined,
      status: ['COMPLETED', 'ATTEMPTED']
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [startDate, endDate, selectedOrgId, selectedClientStoreId]);

  useEffect(() => {
    if (getTasksCalled && !isFiltersApplied()) {
      callGetTasks().catch(error => console.error(error));
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getTasksCalled, filterOptions]);

  const onFiltersReset = () => {
    setSelectedOrgId('all');
    setSelectedClientStoreId('');
    setStartDate(getFirstDateOfCurrentMonth(today));
    setEndDate(today);
    setRowCount(0);
    setCurrentPage(0);
  };

  const trySetEndDate = (date: MomentInput) => {
    if (startDate!.valueOf() >= date!.valueOf()) {
      enqueueSnackbar('Please select a date in the future', {
        variant: 'warning'
      });
      return;
    }
    setEndDate(date);
  };

  return (
    <Stack spacing={2}>
      <Stack>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={4}>
            <TruentityDatePicker
              label="Start Date"
              fullWidth={false}
              sx={{ width: '100%' }}
              value={startDate}
              format="MMM DD, YYYY"
              onChange={date => setStartDate(date as MomentInput)}
            />
          </Grid>

          <Grid item xs={4}>
            <TruentityDatePicker
              label="End Date"
              fullWidth={false}
              sx={{ width: '100%' }}
              value={endDate}
              format="MMM DD, YYYY"
              onChange={date => trySetEndDate(date as MomentInput)}
            />
          </Grid>

          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <FormControl sx={{ width: '100%', marginRight: 1 }} variant="outlined" margin="dense" size="medium">
              <InputLabel id={'organizations-input'}>Organization</InputLabel>

              <Select
                labelId={'organizations'}
                label={'Organizations'}
                placeholder={'select an organization'}
                value={selectedOrgId}
                onChange={event => {
                  setSelectedOrgId(event.target.value);
                }}
              >
                {clientOrganizations.map((item: ClientOrganizationObject) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            {filteredClientStores.length > 0 && (
              <FormControl sx={{ width: '100%' }} variant="outlined" fullWidth margin="dense" size="medium">
                <InputLabel id={'organizations-input'}>Location</InputLabel>

                <Select
                  labelId={'stores'}
                  label={'Stores'}
                  placeholder={'select a store'}
                  value={selectedClientStoreId}
                  onChange={event => {
                    setSelectedClientStoreId(event.target.value);
                  }}
                >
                  {filteredClientStores.map((item: ClientStoreObject) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={1} pb={1}>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
            item
            xs={12}
          >
            <Stack spacing={1} direction="row">
              <Button type="submit" size="small" onClick={onRunReportClicked} isLoading={billingReportLoading}>
                Run Report
              </Button>
              <Button type="button" size="small" onClick={handleScheduleReport} isLoading={createScheduledReportLoading}>
                Schedule Report
              </Button>
              <Button
                disabled={!isFiltersApplied()}
                label={'Reset'}
                type="reset"
                size="small"
                color="secondary"
                onClick={() => onFiltersReset()}
              />
            </Stack>
            <Button type="button" variant="outlined" size="small" onClick={onViewReportsClicked} isLoading={createScheduledReportLoading}>
              View Scheduled Reports
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Paper component={Stack} direction="column" spacing={2}>
        <TruentityDataGrid
          name={'dg-billing'}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => setCurrentPage(page)}
          disableColumnMenu={false}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1
            }
          }}
          slots={{
            loadingOverlay: () => {
              return <LinearProgress />;
            },
            toolbar: () => {
              const billedAmountLabel = 'Total Billed Amount: $' + (totalBilledAmount ? totalBilledAmount?.toFixed(2) : '0.00');
              const bonusAmountLabel = 'Total Bonus Amount: $' + (totalBonusAmount ? totalBonusAmount?.toFixed(2) : '0.00');
              return (
                <Stack>
                  <Stack
                    spacing={3}
                    direction="row"
                    sx={{
                      cursor: 'pointer',
                      margin: '15px',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <Stack spacing={1} direction="row">
                      <Chip
                        sx={{
                          textTransform: 'uppercase',
                          letterSpacing: 2,
                          height: 23,
                          fontSize: '0.80rem'
                        }}
                        label={billedAmountLabel}
                        color="success"
                        variant="outlined"
                      ></Chip>
                    </Stack>
                    <Stack spacing={1} direction="row">
                      <Chip
                        sx={{
                          textTransform: 'uppercase',
                          letterSpacing: 2,
                          height: 23,
                          fontSize: '0.80rem'
                        }}
                        label={bonusAmountLabel}
                        color="success"
                        variant="outlined"
                      ></Chip>
                    </Stack>
                  </Stack>
                  <Stack
                    spacing={3}
                    direction="row"
                    sx={{
                      cursor: 'pointer',
                      margin: '15px',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Stack spacing={1} direction="row">
                      {fetchDataButtonVisible ? (
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          disabled={tasks.length === 0}
                          onClick={() => {
                            onFetchDataClicked();
                          }}
                        >
                          Load for Export
                        </Button>
                      ) : (
                        <>
                          <Button isLoading={exportBillingReportLoading} color="primary" variant="contained" size="small">
                            {billingExportReportData.length > 0 ? (
                              <CSVLink
                                headers={headers}
                                data={newBillingReport(BillingReportType.Full)}
                                filename={createCsvFileName(BillingReportType.Full)}
                                style={{ textDecoration: 'none', color: '#fff' }}
                              >
                                {exportBillingReportLoading ? 'Loading csv...' : 'Export All'}
                              </CSVLink>
                            ) : undefined}
                          </Button>
                          <Button isLoading={exportBillingReportLoading} variant="outlined" size="small">
                            {billingExportReportData.length > 0 ? (
                              <CSVLink
                                headers={headers}
                                data={newBillingReport(BillingReportType.Limited)}
                                filename={createCsvFileName(BillingReportType.Limited)}
                                style={{ textDecoration: 'none', color: '#000' }}
                              >
                                {exportBillingReportLoading ? 'Loading csv...' : 'Export Limited'}
                              </CSVLink>
                            ) : undefined}
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              );
            }
          }}
          rows={tasks}
          rowCount={rowCountState}
          loading={getTasksLoading}
          columns={columns}
          paginationMode="server"
          autoHeight
        />
      </Paper>
    </Stack>
  );
};

export default Billing;
