import type { JSONData } from '@/components/JsonKit/JsonEditor';
import { H3 } from '@/components/Typography';
import type { GetPatientDetailResponse } from '@/graphql/account';
import { GET_PATIENT_DETAIL, UPDATE_USER_PROFILE } from '@/graphql/account';
import { emptyData } from '@/state/patientProfileForm';
import type { FormValues } from '@/types/accountProfile';
import { toDate } from '@/util/date';
import { formatDate } from '@/util/format';
import type { FetchResult } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { Dispatch, SetStateAction } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import PatientDetailsForm from './PatientDetailsForm';
import { transformObjectCase } from '@/util/object';

type Props = {
  truentityId: string;
  setIsVerifyFormChanged: Dispatch<SetStateAction<boolean>>;
};

export type VerifyUserFormHandles = {
  submitForm: () => Promise<FetchResult<unknown> | undefined>;
};

const VerifyUserStep = forwardRef<VerifyUserFormHandles, Props>(({ truentityId, setIsVerifyFormChanged }, ref) => {
  const formData = useRef<JSONData | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [defaultBirthDate, setDefaultBirthDate] = useState<Date | undefined>(undefined);

  const { control, reset, setValue, handleSubmit, watch } = useForm<FormValues>({ defaultValues: emptyData });

  const { loading: isPatientDataLoading } = useQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL, {
    variables: {
      truentityId
    },
    onCompleted: data => handlePatientDataCompleted(data)
  });
  const [updateUserAccountProfile] = useMutation(UPDATE_USER_PROFILE);

  const watchedValues = watch();

  const handlePatientDataCompleted = (data: GetPatientDetailResponse) => {
    const account = data.accountGet;
    const user = account.user;
    const birthDate = toDate(formatDate(account.birthDate, 'YYYY-MM-DD'));
    const patientInfo: FormValues = {
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      birthDate: birthDate,
      gender: account.gender,
      zipcode: account.zipcode,
      addressLine1: account.addressLine1,
      addressLine2: account.addressLine2,
      addressState: account.addressState,
      addressCity: account.addressCity
    };
    setDefaultBirthDate(birthDate);
    reset(patientInfo);
    formData.current = patientInfo;
  };

  const handleSubmitImpl = useCallback(
    async (values: FormValues) => {
      const profileData = {
        userProfile: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName
        },
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        addressState: values.addressState?.toUpperCase(),
        addressCity: values.addressCity,
        zipcode: values.zipcode,
        birthDate: formatDate(values.birthDate, 'YYYY-MM-DD'),
        gender: values.gender
      };

      try {
        return await updateUserAccountProfile({
          variables: {
            truentityId,
            profileData
          }
        });
      } catch (error) {
        enqueueSnackbar('Unable to verify patient data. Please reach out to our support team for assistance.', { variant: 'error' });
        return undefined;
      }
    },
    [truentityId, enqueueSnackbar, updateUserAccountProfile]
  );

  useImperativeHandle(
    ref,
    () => ({
      submitForm: () => {
        return new Promise<FetchResult<unknown> | undefined>((resolve, reject) => {
          handleSubmit(async data => {
            try {
              const result = await handleSubmitImpl(data);
              resolve(result);
            } catch (error) {
              reject(error);
            }
          })();
        });
      }
    }),
    [handleSubmit, handleSubmitImpl]
  );

  useEffect(() => {
    const watchedDataLower = transformObjectCase(watchedValues, str => str.toLowerCase());
    const formDataLower = transformObjectCase(formData.current, str => str.toLowerCase());

    const isEqual = JSON.stringify(watchedDataLower) === JSON.stringify(formDataLower);

    setIsVerifyFormChanged(!isEqual);
  }, [watchedValues, setIsVerifyFormChanged]);

  return (
    <Stack component="form">
      <H3
        sx={{
          marginBottom: 3
        }}
      >
        Patient Profile
      </H3>
      {isPatientDataLoading && <LinearProgress sx={{ mb: 2 }} />}
      <PatientDetailsForm control={control} setValue={setValue} defaultBirthDate={defaultBirthDate} isLoading={isPatientDataLoading} />
    </Stack>
  );
});

export default VerifyUserStep;
