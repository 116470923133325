import { ADD_ANNOUNCEMENT, UPDATE_ANNOUNCEMENT } from '@/graphql/announcements';
import AnnouncementMediaForm from '@/routes/Administration/Components/AnnouncementMediaForm';
import ComposeAnnouncementForm from '@/routes/Administration/Components/ComposeAnnouncementForm';
import GeneralAnnouncementDetailsForm from '@/routes/Administration/Components/GeneralAnnouncementDetailsForm';
import SelectAnnouncementRecipient from '@/routes/Administration/Components/SelectAnnouncementRecipient';
import type { Announcement, RecipientInput } from '@/types/announcement';
import { AnnouncementType } from '@/types/announcement';
import type { TruentityDateInput } from '@/types/date';
import { useMutation } from '@apollo/client';
import { DialogContent } from '@mui/material';
import type { GridRowId } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { UseFormTrigger } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import Button from '../Button';
import HorizontalStepper from '../Stepper/HorizontalStepper';
import type { FileWithOrigin } from '../TruentityDropzone';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  announcement?: Announcement;
  onSuccess: () => void;
};

export type GeneralDetailsForm = {
  title: string;
  startTime: TruentityDateInput;
  endTime: TruentityDateInput;
  type: AnnouncementType;
  alwaysShow: boolean;
};

export type ContentForm = {
  content: string;
};

type UpdatedFile = {
  removedFiles: string[];
  addedFiles: File[];
};

const generalDetailsFormDefaultValues: GeneralDetailsForm = {
  title: '',
  startTime: null,
  endTime: null,
  type: AnnouncementType.PRIMARY,
  alwaysShow: false
};

const contentFormDefaultValue: ContentForm = {
  content: ''
};

const ManageAnnouncementDialog = ({ title, hideDialog, announcement, onSuccess, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control: generalAnnouncementControl,
    formState: { isDirty: generalDetailsIsDirty, isValid: generalDetailsIsValid },
    reset: generalAnnouncementReset,
    trigger: generalAnnouncementTrigger,
    watch: generalAnnouncementWatch,
    getValues: generalAnnouncementGetValues
  } = useForm<GeneralDetailsForm>({
    defaultValues: generalDetailsFormDefaultValues,
    mode: 'onChange'
  });

  const {
    control: contentForm,
    formState: { isDirty: contentIsDirty, isValid: contentIsValid },
    reset: contentFormReset,
    trigger: contentTrigger,
    getValues: contentGetValues
  } = useForm<ContentForm>({
    defaultValues: contentFormDefaultValue,
    mode: 'onChange'
  });

  const initialFiles = useRef<
    {
      id: string;
      s3Key: string;
      s3PresignedUrl: string;
    }[]
  >([]);

  const [files, setFiles] = useState<FileWithOrigin[]>([]);
  const [updatedFiles, setUpdatedFiles] = useState<UpdatedFile>({
    removedFiles: [],
    addedFiles: []
  });
  const [selectedRelyingPartyIds, setSelectedRelyingPartyIds] = useState<GridRowId[]>([]);
  const [currentStep, setCurrentStep] = useState(0);

  const isUpdateAnnouncement = useMemo(() => !!announcement, [announcement]);

  const announcementType = generalAnnouncementWatch('type');

  const [createAnnouncement, { loading: createAnnouncementLoading }] = useMutation(ADD_ANNOUNCEMENT, {
    onCompleted: data => {
      enqueueSnackbar(data.createAnnouncement.message, { variant: 'success' });
      onSuccess();
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unable to add the announcement. Please try again later.', { variant: 'error' });
      }
    }
  });

  const [updateAnnouncement, { loading: updateAnnouncementLoading }] = useMutation(UPDATE_ANNOUNCEMENT, {
    onCompleted: data => {
      enqueueSnackbar(data.updateAnnouncement.message, { variant: 'success' });
      onSuccess();
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unable to update the announcement. Please try again later.', { variant: 'error' });
      }
    }
  });

  const increaseCurrentStep = () => setCurrentStep((previous: number) => previous + 1);
  const decreaseCurrentStep = () => setCurrentStep((previous: number) => previous - 1);

  const handleNextButton = async (trigger: UseFormTrigger<ContentForm> | UseFormTrigger<GeneralDetailsForm>) => {
    const isValid = await trigger();
    if (isValid) {
      increaseCurrentStep();
    }
  };

  const handleSave = async () => {
    const generalValues: GeneralDetailsForm = generalAnnouncementGetValues();
    const contentValues: ContentForm = contentGetValues();

    const formValues = { ...generalValues, ...contentValues };

    const restructureRecipients: RecipientInput[] = selectedRelyingPartyIds.map(relyingPartyId => ({
      relyingPartyId: relyingPartyId as string,
      relyingPartyAdminId: 'ALL'
    }));

    if (!isUpdateAnnouncement) {
      await createAnnouncement({
        variables: {
          ...formValues,
          medias: files.map(file => file.mediaFile) || [],
          recipients: restructureRecipients
        }
      });
    } else {
      await updateAnnouncement({
        variables: {
          id: announcement?.id,
          ...formValues,
          mediasToAdd: updatedFiles.addedFiles,
          mediaKeysToRemove: updatedFiles.removedFiles,
          recipients: restructureRecipients
        }
      });
    }
  };

  const updateMedias = () => {
    const initialIds = new Set(initialFiles.current.map(file => file.id));
    const currentIds = new Set(files.map(file => file.id));

    const addedFiles = files.filter(file => !initialIds.has(file.id)).map(file => file.mediaFile as File);

    const removedFiles = initialFiles.current.filter(file => !currentIds.has(file.id)).map(file => file.s3Key);

    setUpdatedFiles({ addedFiles, removedFiles });

    increaseCurrentStep();
  };

  useEffect(() => {
    if (announcementType === AnnouncementType.SECONDARY && files.length > 0) {
      setFiles([]);
    }
  }, [announcementType, files.length]);

  useEffect(() => {
    if (!announcement) return;

    const {
      title,
      content,
      type,
      startTime,
      endTime,
      alwaysShow,
      media = [],
      announcementRecipientsRelyingParty: organizations = []
    } = announcement;

    generalAnnouncementReset(
      {
        title,
        type,
        startTime: moment(startTime),
        endTime: moment(endTime),
        alwaysShow
      },
      {
        keepDefaultValues: true
      }
    );

    contentFormReset({ content }, { keepDefaultValues: true });

    const reArrangedFiles =
      media?.map(({ s3Key, s3PresignedUrl }) => ({
        id: uuidv4(),
        s3Key,
        s3PresignedUrl
      })) || [];

    setFiles(
      reArrangedFiles.map(({ id, s3PresignedUrl }) => ({
        id,
        mediaFile: s3PresignedUrl
      }))
    );

    initialFiles.current = reArrangedFiles;

    setSelectedRelyingPartyIds(organizations?.map(({ id }) => id) || []);
  }, [announcement, generalAnnouncementReset, contentFormReset]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props} fullWidth maxWidth="md">
      <DialogContent>
        <HorizontalStepper
          steps={[
            {
              label: 'General Details',
              children: <GeneralAnnouncementDetailsForm control={generalAnnouncementControl} />,
              canNext: () => generalDetailsIsDirty && generalDetailsIsValid,
              tryNext: () => handleNextButton(generalAnnouncementTrigger),
              optional: isUpdateAnnouncement
            },
            {
              label: 'Compose Announcement',
              children: <ComposeAnnouncementForm control={contentForm} announcementType={announcementType} />,
              canNext: () => contentIsDirty && contentIsValid,
              tryNext: () => handleNextButton(contentTrigger),
              tryBack: decreaseCurrentStep,
              optional: isUpdateAnnouncement
            },
            ...(announcementType === AnnouncementType.PRIMARY
              ? [
                  {
                    label: 'Add Media (optional)',
                    children: <AnnouncementMediaForm setFiles={setFiles} files={files} />,
                    canNext: () => true,
                    tryNext: () => {
                      !isUpdateAnnouncement ? increaseCurrentStep() : updateMedias();
                    },
                    tryBack: decreaseCurrentStep,
                    optional: true
                  }
                ]
              : []),
            {
              label: 'Choose Recipients',
              children: (
                <SelectAnnouncementRecipient
                  selectedRelyingPartyIds={selectedRelyingPartyIds}
                  setSelectedRelyingPartyIds={setSelectedRelyingPartyIds}
                />
              ),
              tryBack: decreaseCurrentStep,
              customActionButtons: [
                <Button
                  color="success"
                  disabled={!isUpdateAnnouncement && selectedRelyingPartyIds.length <= 0}
                  onClick={handleSave}
                  isLoading={createAnnouncementLoading || updateAnnouncementLoading}
                >
                  {isUpdateAnnouncement ? 'Update' : 'Save'}
                </Button>
              ],
              optional: isUpdateAnnouncement
            }
          ]}
          currentStep={currentStep}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default ManageAnnouncementDialog;
