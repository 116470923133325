import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import IcdDescriptionDialog from '@/components/Dialogs/IcdDescriptionDialog';
import LoadingOverlay from '@/components/LoadingOverlay';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H1, H2 } from '@/components/Typography';
import Link from '@/elements/Link';
import type { GetRpmClaimsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_CLAIMS, UPDATE_RPM_CLAIMS_BILLINGS, UPDATE_RPM_CLAIMS_BILLINGS_ALL } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { MedicalServices } from '@/types/admin';
import type { RpmClaimsType } from '@/types/remotePatientMonitoring';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { checkIfCurrentMonth, formatDate, formatDateIgnoreTZ, formatTime } from '@/util/format';
import { pathnameIncludes } from '@/util/location';
import { getClaimsChipColor } from '@/util/rpm';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Chip, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

const csvHeaders = [
  { label: 'EHR ID', key: 'ehrId' },
  { label: 'DOB', key: 'dob' },
  { label: 'FIRST NAME', key: 'firstName' },
  { label: 'LAST NAME', key: 'lastName' },
  { label: 'TYPE', key: 'type' },
  { label: 'ICD10 CODE', key: 'icd10Code' },
  { label: 'ICD10 DESCRIPTION', key: 'icd10Description' }
];

const RpmClaims = () => {
  const isRpmBillingPage = pathnameIncludes('/reports/rpm-billing');
  const currentDateTime = new Date();
  const { showModal } = useModal();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();

  const [claimsData, setClaimsData] = useState<RpmClaimsType[]>([]);

  const isRunReport = useMemo(() => (claimsData?.length === 0 ? 'RUNREPORT' : 'RERUNREPORT'), [claimsData]);
  const activityWord = useMemo(() => (isRunReport === 'RUNREPORT' ? 'run' : 'rerun'), [isRunReport]);

  const [totalRowCount, setTotalRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const [monthAndYear, setMonthAndYear] = useState<DateInput>(formatTime(currentDateTime, 'MMM YYYY'));
  const [fetchDataButtonVisible, setFetchDataButtonVisible] = useState<boolean>(true);
  const [generateButtonClicked, setGenerateButtonClicked] = useState<boolean>(false);

  const [getRpmClaims, { data: rpmClaimsData, refetch: refetchRpmClaimsData, loading: rpmClaimsLoading }] =
    useLazyQuery<GetRpmClaimsResponse>(GET_CLAIMS, {
      fetchPolicy: 'cache-and-network'
    });

  const [updateRpmClaims, { loading: updateRpmClaimsLoading }] = useMutation(
    isRpmBillingPage ? UPDATE_RPM_CLAIMS_BILLINGS_ALL : UPDATE_RPM_CLAIMS_BILLINGS
  );

  const callClaimsQuery = async (isExport: boolean) => {
    await getRpmClaims({
      variables: {
        monthYear: formatTime(monthAndYear, 'MMM YYYY'),
        pageNum: isExport ? 1 : currentPage + 1,
        pageSize: isExport ? 100 : DEFAULT_PAGE_SIZE,
        isExport: isExport,
        all: isRpmBillingPage
      }
    }).catch((error: ApolloError) => {
      console.error(error);
    });
  };

  const onFetchExportButtonClicked = () => {
    setFetchDataButtonVisible(false);
    exportDataToCsv();
  };

  const exportDataToCsv = () => {
    callClaimsQuery(true);
  };

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const getRpmClaimsDataFiltered = () => {
    const DEFAULT_STRING = 'Not Provided';
    const includeCompany = isRpmBillingPage;

    const activitiesDataObject = claimsData?.map(data => {
      const baseData = {
        ehrId: data?.ehrId || DEFAULT_STRING,
        dob: data?.account.birthDate || DEFAULT_STRING,
        firstName: data?.account?.user.firstName || DEFAULT_STRING,
        lastName: data.account?.user.lastName || DEFAULT_STRING,
        type: data?.cptCode && data?.description ? `${data.cptCode} - ${data.description}` : DEFAULT_STRING,
        icd10Code: data?.diagnosesCodes || DEFAULT_STRING,
        icd10Description: data?.diagnosesNames || DEFAULT_STRING
      };

      if (includeCompany) {
        return {
          ...baseData,
          company: data?.relyingParty.name || DEFAULT_STRING
        };
      }

      return baseData;
    });

    return activitiesDataObject;
  };

  const modifiedCsvHeaders = [...csvHeaders];
  if (isRpmBillingPage) {
    modifiedCsvHeaders.splice(1, 0, { label: 'COMPANY', key: 'company' });
  }

  const generateCurrentMonthData = async () => {
    try {
      const response = await updateRpmClaims({
        variables: { monthYear: formatTime(monthAndYear, 'MMM YYYY') }
      });
      setGenerateButtonClicked(true);
      const data = isRpmBillingPage ? response?.data?.updateRpmBillingAll : response?.data?.updateRpmBilling;
      if (data?.status === 'Success') {
        enqueueSnackbar('RPM Claims generated successfully', {
          variant: 'success'
        });
        refetchRpmClaimsData();
      } else {
        enqueueSnackbar(
          data?.errors && data?.errors?.length > 0 ? data?.errors[0]?.message : 'Something went wrong. Could not generate RPM Claims.',
          {
            variant: 'error'
          }
        );
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong. Could not generate RPM Claims.', {
        variant: 'error'
      });
    }
  };

  const renderIcd10Values = ({ value }) => {
    const values = value.split(', ');
    const displayValues = values.slice(0, 2).map((val, index) => (
      <Box key={index} textTransform="capitalize">
        {val}
      </Box>
    ));

    return <Stack>{displayValues}</Stack>;
  };

  const showIcdDescriptionModal = (icdCodes: string, icdNames: string) => {
    const modalRef = showModal(IcdDescriptionDialog, {
      title: 'ICD10 Codes and Descriptions',
      hideDialog: () => {
        modalRef.hide();
      },
      diagnosesCodes: icdCodes,
      diagnosesNames: icdNames
    });
  };

  const showConfirmationModal = useCallback(
    (actionWord?: string) => {
      actionWord = actionWord ? actionWord : activityWord;

      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Claims Report Generation',
        message: `Are you sure you want to ${actionWord} the claims report for <b>${monthAndYear}</b>? This action will initiate the process to ${actionWord} the report corresponding to the chosen month and year.`,
        onAgree: () => {
          modal.hide();
          generateCurrentMonthData();
        },
        onDisagree: () => modal.hide()
      });
    },
    [monthAndYear, activityWord, generateCurrentMonthData]
  );

  const Icd10CodeRenderer = ({ icdCodes, icdNames }) => {
    const codes = icdCodes.split(',');
    const hasMoreCodes = codes.length > 2;

    return (
      hasMoreCodes && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box>+ {`+ ${codes.length - 2}`}</Box>
          <IconButton onClick={() => showIcdDescriptionModal(icdCodes, icdNames)}>
            <ChevronRightIcon />
          </IconButton>
        </Stack>
      )
    );
  };

  const generateFilename = (currentUser, monthAndYear) => {
    const relyingPartyName = currentUser?.relyingParty?.name ?? '';
    const formattedDate = formatTime(monthAndYear, 'MMM, yyyy');

    if (isRpmBillingPage) {
      return `RPM Claims - ${formattedDate}.csv`;
    } else {
      return `${relyingPartyName} - RPM Claims - ${formattedDate}.csv`;
    }
  };

  useEffect(() => {
    if (rpmClaimsData?.getRpmClaims?.billingAccounts) {
      setClaimsData(rpmClaimsData?.getRpmClaims?.billingAccounts ?? []);
      setTotalRowCount(rpmClaimsData?.getRpmClaims?.meta?.totalCount || 0);
    }
  }, [rpmClaimsData]);

  useEffect(() => {
    callClaimsQuery(false);
    setFetchDataButtonVisible(true);
  }, [monthAndYear, currentPage]);

  const columns: GridColDef[] = useMemo(() => {
    const baseColumns: GridColDef[] = [
      {
        field: 'ehrId',
        headerName: 'EHR Id',
        flex: 1,
        maxWidth: 150,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params.row.ehrId ?? '',
        sortable: false
      },
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        maxWidth: 250,
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          const name = getAccountUserFullName(params.row.account.user);
          const status = params.row.account.rpmStatus;
          const accountMonitoringDevices = params.row.account.accountsMonitoringDevices;
          const relying_party_id = params.row?.relyingParty.id;

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {currentUser?.relyingParty.id === relying_party_id ? (
                <Link
                  to={getAccountRedirectUrl(params?.row?.account.truentityId, MedicalServices.RPM, status, accountMonitoringDevices) ?? ''}
                >
                  {name}
                </Link>
              ) : (
                <span>{name}</span>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        flex: 1,
        maxWidth: 150,
        valueGetter: params => formatDateIgnoreTZ(params.row.account.birthDate) ?? '',
        sortable: false
      },
      {
        field: 'dateEnrolled',
        headerName: 'Date Enrolled',
        sortable: true,
        flex: 0.8,
        align: 'left',
        headerAlign: 'left',
        type: 'date',
        valueGetter: params => {
          const rpmEnrolledAt = params.row.account?.rpmEnrolledAt;
          return rpmEnrolledAt ? new Date(rpmEnrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        renderCell: params => {
          const { cptCode, description } = params.row;
          const { backgroundColor, textColor } = getClaimsChipColor(cptCode);

          return (
            <Stack direction="row" sx={{ userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              <Chip label={`${cptCode} - ${description}`} sx={{ backgroundColor: backgroundColor, color: textColor }} />
            </Stack>
          );
        }
      },
      {
        field: 'icd10Code',
        headerName: 'ICD10 Code',
        flex: 1,
        maxWidth: 100,
        renderCell: params => renderIcd10Values({ value: params.row.diagnosesCodes || '' }),
        sortable: false
      },
      {
        field: 'action',
        headerName: '',
        sortable: false,
        maxWidth: 20,
        filterable: false,
        pinnable: false,
        align: 'center',
        headerAlign: 'left',
        renderCell: params => Icd10CodeRenderer({ icdCodes: params.row.diagnosesCodes || '', icdNames: params.row.diagnosesNames || '' })
      },
      {
        field: 'icd10Description',
        headerName: 'ICD10 Description',
        flex: 1,
        renderCell: params => renderIcd10Values({ value: params.row.diagnosesNames || '' }),
        sortable: false
      }
    ];

    if (isRpmBillingPage) {
      baseColumns.splice(2, 0, {
        field: 'company',
        headerName: 'Company',
        flex: 1,
        maxWidth: 200,
        valueGetter: params => params.row.relyingParty?.name || 'Not Provided',
        sortable: false
      });
    }

    return baseColumns;
  }, [pathnameIncludes, currentUser]);

  const ClaimsDataGrid = () => {
    return (
      <Paper padding={2} elevation={0} component={Stack} direction="column" spacing={2} overflow={'auto'}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          {fetchDataButtonVisible ? (
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={claimsData.length === 0}
              onClick={() => {
                onFetchExportButtonClicked();
              }}
            >
              Load for Export
            </Button>
          ) : (
            <Button isLoading={rpmClaimsLoading} color="primary" variant="contained" size="small">
              {claimsData && claimsData.length > 0 && (
                <CSVLink
                  headers={modifiedCsvHeaders}
                  data={getRpmClaimsDataFiltered()}
                  filename={generateFilename(currentUser, monthAndYear)}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {rpmClaimsLoading ? 'Loading CSV...' : 'Export'}
                </CSVLink>
              )}
            </Button>
          )}
        </Stack>
        <TruentityDataGrid
          name={'rpm-claims'}
          autoHeight
          rows={claimsData}
          rowCount={totalRowCount}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={rpmClaimsLoading}
          paginationMode="server"
          columns={columns}
          disableRowSelectionOnClick
          sx={{ backgroundColor: '#ffffff' }}
        />
      </Paper>
    );
  };

  return (
    <>
      <Stack>
        <Stack
          sx={{
            padding: 3,
            backgroundColor: color.paper,
            borderRadius: '8px',
            marginBottom: 3
          }}
          spacing={2}
          justifyContent={'space-between'}
          alignItems={'center'}
          direction={'row'}
        >
          <H1 textAlign="left" sx={{ flex: 4 }}>
            {`${isRpmBillingPage ? 'All RPM Claims' : 'RPM Claims'}`}
          </H1>
          <TruentityDatePicker
            showMonth={true}
            showYear={true}
            value={monthAndYear}
            onChange={handleMonthYear}
            views={['month', 'year']}
            sx={{ width: '415px' }}
          />
          <Button variant="contained" color="primary" onClick={() => showConfirmationModal()}>
            {`${activityWord} Report`}
          </Button>
        </Stack>

        <Stack width="100%" flexDirection="column" justifyContent="stretch" alignItems="stretch">
          <>
            {checkIfCurrentMonth(monthAndYear) ? (
              !rpmClaimsLoading && (
                <>
                  {claimsData?.length === 0 && !generateButtonClicked ? (
                    <Paper>
                      <Stack alignItems="center" justifyContent="center" height="50vh">
                        <H2 sx={{ color: color.grey500, lineHeight: '32px', fontWeight: 500, fontSize: '20px' }}>
                          The data for the current month has not been generated.
                        </H2>
                        <H2 sx={{ color: color.grey500, lineHeight: '32px', fontWeight: 500, fontSize: '20px' }}>
                          Click the following button to generate data up to today’s date.
                        </H2>
                        <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={() => showConfirmationModal('generate')}>
                          Generate Data
                        </Button>
                      </Stack>
                    </Paper>
                  ) : (
                    <ClaimsDataGrid />
                  )}
                </>
              )
            ) : (
              <ClaimsDataGrid />
            )}
          </>
        </Stack>
      </Stack>
      <LoadingOverlay text={'Loading Rpm Claims...'} active={updateRpmClaimsLoading || rpmClaimsLoading} />
    </>
  );
};

export default RpmClaims;
