import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { GetScheduledRpoertsResponse } from '@/graphql/administration';
import { GET_SCHEDULED_REPORTS } from '@/graphql/administration';
import { ScheduledReportStatusEnum, ScheduledReportTypeEnum, type ScheduledReportType } from '@/types/administration';
import { formatDateAndTime } from '@/util/format';
import { useQuery } from '@apollo/client';
import CachedIcon from '@mui/icons-material/Cached';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Chip, LinearProgress, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

type ScheduledReportsListProps = {
  reportType: ScheduledReportTypeEnum;
  triggerReFetch?: boolean;
  setTriggerReFetch?: (params: boolean) => void;
};

export const ScheduledReportsList: React.FC<ScheduledReportsListProps> = ({
  reportType,
  triggerReFetch = false,
  setTriggerReFetch = () => {}
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(0);

  const {
    data: scheduledReports,
    loading: scheduledReportsLoading,
    refetch: refetchScheduledReports,
    error: scheduledReportsError
  } = useQuery<GetScheduledRpoertsResponse>(GET_SCHEDULED_REPORTS, {
    variables: {
      filterOptions: {
        reportType: reportType
      },
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network'
  });

  const columns: GridColDef<ScheduledReportType>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        flex: 1
      },
      {
        field: 'organization',
        headerName: 'Organization',
        sortable: true,
        flex: 1
      },
      {
        field: 'status',
        headerName: 'Status',
        renderCell: params => {
          if (params.value === ScheduledReportStatusEnum.COMPLETED) {
            return <Chip variant="filled" color="success" label="Completed" />;
          } else if (params.value === ScheduledReportStatusEnum.FAILED) {
            return <Chip variant="filled" color="error" label="Failed" />;
          } else if (params.value === ScheduledReportStatusEnum.PROCESSING) {
            return <Chip variant="filled" color="warning" label="Processing" />;
          } else if (params.value === ScheduledReportStatusEnum.SCHEDULED) {
            return <Chip variant="filled" color="info" label="Scheduled" />;
          } else {
            return <span>-</span>;
          }
        },
        sortable: false,
        flex: 1
      },
      {
        field: 'reportType',
        headerName: 'Report Type',
        valueGetter: params => {
          if (params.row.reportType === ScheduledReportTypeEnum.MTM_BILLING_REPORT) {
            return 'MTM Billing Report';
          } else if (params.row.reportType === ScheduledReportTypeEnum.RPM_PERFORMANCE_REPORT) {
            return 'RPM Performance Report';
          } else {
            return '-';
          }
        },
        sortable: false,
        flex: 1
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        type: 'string',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return formatDateAndTime(params?.row?.createdAt);
        }
      },
      {
        field: 's3Url',
        headerName: 'Download CSV',
        renderCell: params => {
          if ((params.value !== '' || params.value !== null) && params.row.status === ScheduledReportStatusEnum.COMPLETED) {
            return (
              <Button
                size="small"
                variant={'text'}
                startIcon={<FileOpenIcon />}
                disableElevation
                onClick={() => {
                  window.open(params.value, '_blank');
                }}
              />
            );
          } else {
            return <span>-</span>;
          }
        }
      }
    ],
    []
  );

  useEffect(() => {
    if (scheduledReportsError) {
      if (triggerReFetch) {
        setTriggerReFetch(false);
      }
      enqueueSnackbar('Failed to retrieve reports', { variant: 'error' });
    }
  }, [scheduledReportsError]);

  useEffect(() => {
    if (scheduledReports && triggerReFetch) {
      setTriggerReFetch(false);
    }
  }, [scheduledReports]);

  useEffect(() => {
    if (triggerReFetch) {
      refetchScheduledReports({
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      });
    }
  }, [triggerReFetch]);

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        width: '100%'
      }}
      spacing={1}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Button
          label={'Refresh'}
          variant={'outlined'}
          disabled={scheduledReportsLoading}
          startIcon={<CachedIcon />}
          onClick={() => {
            refetchScheduledReports({
              pageNum: currentPage + 1,
              pageSize: DEFAULT_PAGE_SIZE
            });
          }}
        />
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          width: '100%'
        }}
      >
        <TruentityDataGrid
          name={'scheduled-reports-list'}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          autoHeight
          rows={scheduledReports?.getScheduledReports?.scheduledReports ?? []}
          columns={columns}
          loading={scheduledReportsLoading}
          slots={{
            loadingOverlay: LinearProgress
          }}
          disableRowSelectionOnClick={true}
          paginationMode={'server'}
          rowCount={scheduledReports?.getScheduledReports?.meta?.totalCount ?? 0}
        />
      </Stack>
    </Stack>
  );
};

export default ScheduledReportsList;
