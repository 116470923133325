import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { NewDeviceDialog } from '@/components/Dialogs';
import { theme } from '@/styles/mui-theme';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import type { LinearProgressProps } from '@mui/material';
import { Box, Chip, IconButton, LinearProgress, Paper, Stack, Typography } from '@mui/material';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { H3 } from '@/components/Typography';
import type { GetAccountMonitoringDevicesResponse } from '@/graphql/remotePatientMonitoring';
import { DELETE_ACCOUNT_MONITORING_DEVICE, GET_ACCOUNT_MONITORING_DEVICES } from '@/graphql/remotePatientMonitoring';
import type { AccountsMonitoringDeviceType } from '@/types/remotePatientMonitoring';
import { StatusType } from '@/types/remotePatientMonitoring';

import useAutoEnrollPatient from '@/hooks/useAutoEnrollPatient';
import { RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { formatDateAndTime } from '@/util/format';
import { statusColorMapping } from '@/util/rpm';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation } from '@apollo/client';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1, backgroundColor: 'lightgrey' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const DeviceManagement = () => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { isReadOnly } = useRpmWorkflowStore();
  const { triggerAutoEnrollment } = useAutoEnrollPatient({
    notConsiderForRpmEnrollments: [RpmWorkflowTab.DEVICES]
  });

  const [monitoringDevices, setMonitoringDevices] = useState<AccountsMonitoringDeviceType[]>([]);
  const [getMonitoringDevicesQuery, { data: monitoringDevicesData, refetch: monitoringDevicesRefetch, loading: monitoringDevicesLoading }] =
    useLazyQuery<GetAccountMonitoringDevicesResponse>(GET_ACCOUNT_MONITORING_DEVICES, { fetchPolicy: 'cache-and-network' });
  const [deleteAccountDevice] = useMutation(DELETE_ACCOUNT_MONITORING_DEVICE);

  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  const getMonitoringDevices = useCallback(async () => {
    try {
      await getMonitoringDevicesQuery({
        variables: {
          truentityId: id,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1
        }
      });
    } catch (error) {
      enqueueSnackbar('Failed to retrieve devices', { variant: 'error' });
    }
  }, [currentPage, enqueueSnackbar, getMonitoringDevicesQuery, id]);

  const removeMonitoringDevice = useCallback(
    async (deviceId: string) => {
      try {
        deleteAccountDevice({
          variables: {
            truentityId: id,
            deviceId: deviceId
          }
        })
          .then(response => {
            if (!response.data?.removeAccountDevice) {
              return;
            }
            const data = response.data.removeAccountDevice;
            const variant = data.status === 'Success' ? 'success' : 'error';

            enqueueSnackbar(data.message, {
              variant
            });
            monitoringDevicesRefetch();
          })
          .catch(error => {
            console.error(error);
            enqueueSnackbar('Unable to delete device', {
              variant: 'error'
            });
          });
      } catch (error) {
        enqueueSnackbar('Failed to delete device', { variant: 'error' });
      }
    },
    [deleteAccountDevice, enqueueSnackbar, id, monitoringDevicesRefetch]
  );

  const showAddNewDeviceDialog = (title: string) => {
    const modal = showModal(NewDeviceDialog, {
      title,
      hideDialog: () => {
        monitoringDevicesRefetch().finally(() => modal.hide());
      },
      id,
      triggerAutoEnrollment
    });
  };

  const openNewDeviceDialog = () => {
    showAddNewDeviceDialog('Add a device');
  };

  const LinearProgressBarBackgroundColor = (signalStrength: number): string => {
    if (!signalStrength || typeof signalStrength !== 'number') return '#d3d3d3';
    if (signalStrength >= 70) {
      return '#32cd32';
    } else if (signalStrength >= 55) {
      return '#ff8c00';
    } else if (signalStrength >= 35) {
      return '#ff0000';
    } else {
      return '#d3d3d3';
    }
  };

  const showDeleteConfirmation = useCallback(
    (deviceId: string) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Device Deletion',
        message: 'Are you sure you want to delete this monitoring device? This action cannot be undone',
        onAgree: () => {
          removeMonitoringDevice(deviceId).finally(() => modal.hide());
        },
        onDisagree: () => modal.hide()
      });
    },
    [removeMonitoringDevice, showModal]
  );

  const showEditDeviceDialog = useCallback(
    (title: string, deviceId: string) => {
      const modal = showModal(NewDeviceDialog, {
        title,
        hideDialog: () => {
          monitoringDevicesRefetch().finally(() => modal.hide());
        },
        id,
        deviceId,
        triggerAutoEnrollment
      });
    },
    [id, monitoringDevicesRefetch, showModal, triggerAutoEnrollment]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'deviceName',
        headerName: 'Name',
        maxWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params?.row?.monitoringDevice?.name.toUpperCase() ?? 'N/A'
      },
      {
        field: 'hardwareId',
        headerName: 'Hardware ID',
        minWidth: 300,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'gatewayId',
        headerName: 'Gateway ID',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => {
          return params?.row?.formattedGatewayId ?? 'Unavailable';
        }
      },
      {
        field: 'lastGatewayCheckingTime',
        headerName: 'Last Gateway Check-in',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params =>
          params?.row?.lastGatewayCheckingTime ? formatDateAndTime(params?.row?.lastGatewayCheckingTime) ?? 'Unavailable' : 'Unavailable'
      },
      {
        field: 'signalStrength',
        headerName: 'Last Signal Strength',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValue => {
          const signalStrength = cellValue?.row?.signalStrengthPercentage || 0;
          return (
            <Box flexGrow={1}>
              <LinearProgressWithLabel
                variant="determinate"
                value={signalStrength}
                sx={{
                  height: 9,
                  borderRadius: 5,
                  cursor: 'pointer',
                  '& .MuiLinearProgress-bar': { backgroundColor: LinearProgressBarBackgroundColor(signalStrength) }
                }}
              />
            </Box>
          );
        }
      },
      {
        field: 'status',
        headerName: 'Device Status',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          return <Chip label={params?.value} color={statusColorMapping.get(params?.value) ?? 'warning'} variant="filled" />;
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        minWidth: 110,
        renderCell: cellValues => {
          return (
            <Stack spacing={2} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton color="primary" disabled={isReadOnly} onClick={() => showEditDeviceDialog('Edit device', cellValues?.row?.id)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton color="primary" disabled={isReadOnly} onClick={() => showDeleteConfirmation(cellValues?.row?.id)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    [isReadOnly, showDeleteConfirmation, showEditDeviceDialog]
  );

  useEffect(() => {
    if (monitoringDevicesData && monitoringDevicesData?.getAccountDevices)
      return setMonitoringDevices(monitoringDevicesData?.getAccountDevices?.devices ?? []);
  }, [monitoringDevicesData, monitoringDevicesData?.getAccountDevices?.devices]);

  useEffect(() => {
    getMonitoringDevices().catch(err => console.error(err));
  }, [id, currentPage, getMonitoringDevices]);

  return (
    <Paper component={Stack} sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }} elevation={0}>
      <Stack component="form" spacing={3}>
        <Stack direction="row" p={1} sx={{ justifyContent: 'space-between' }}>
          <H3>Devices</H3>
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <Button
              disabled={isReadOnly || monitoringDevicesData?.getAccountDevices.devices?.length === 0}
              startIcon={<DataThresholdingIcon />}
              label={'Readings'}
              onClick={() => navigate(`/patients/${id}/details/rpm/readings`)}
            />
            <Button
              variant={'outlined'}
              startIcon={<CachedIcon />}
              disableElevation
              onClick={() => monitoringDevicesRefetch()}
              disabled={isReadOnly}
            >
              Refresh
            </Button>
          </Stack>
        </Stack>

        <Paper component={Stack} direction="column" spacing={2} elevation={0}>
          <TruentityDataGrid
            name={'dg-device-management'}
            autoHeight
            rows={monitoringDevices}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            paginationMode="server"
            columns={columns}
            hideFooterPagination
            hideFooter
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            isRowSelectable={params => params.row.status === StatusType.Connected}
            sx={{ backgroundColor: '#ffffff' }}
            disabled={isReadOnly}
            loading={monitoringDevicesLoading}
          />
        </Paper>
        <Stack alignItems="flex-end">
          <Button
            variant={'contained'}
            startIcon={<AddIcon />}
            disableElevation
            onClick={e => {
              e.preventDefault();
              openNewDeviceDialog();
            }}
            disabled={isReadOnly}
          >
            Add a device
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default DeviceManagement;
