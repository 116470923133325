import ChipsList from '@/components/ChipsList';
import CoPilotWindow from '@/components/CoPilotWindow';
import { DEFAULT_PAGE_SIZE, HIGH_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { AssignRelyingPartyAdminDialog, CreateRequestDialog, FollowUpAddEditDialog, MedRecDialog } from '@/components/Dialogs';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import LoadingOverlay from '@/components/LoadingOverlay';
import MedConsultWindow from '@/components/MedConsultWindow';
import { MessageDrawer } from '@/components/MessageDrawer/MessageDrawer';
import MessageTemplatesList from '@/components/MessageTemplatesList/MessageTemplatesList';
import MedSideMenu from '@/components/SideMenus/MedSideMenu';
import type { NavigationSidebarMenuItem } from '@/components/SideMenuTree';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { H4 } from '@/components/Typography';
import type { PatientDetailContextType } from '@/context/patientDetailContext';
import PatientDetailContext from '@/context/patientDetailContext';
import SideMenuContext from '@/context/sideMenuContext';
import type {
  GetAllPioneerEventsResponse,
  GetPatientDetailResponse,
  GetRelyingPartyAdminsResponse,
  PioneerRxEventTypes
} from '@/graphql/account';
import { GET_ALL_PIONEER_RX_EVENTS, GET_PATIENT_DETAIL, GET_RELYING_PARTY_ADMINS } from '@/graphql/account';
import type { GetMedicationsResponse, GetMedrecSnapshotsResponse, ResetAllscriptsMedicationsResponse } from '@/graphql/medication';
import { GET_MEDICATIONS, GET_MED_REC_SNAPSHOTS, RESET_MEDICATIONS } from '@/graphql/medication';
import type { AccountProvidersResponse, GetAccountNotesByFilterResponse } from '@/graphql/remotePatientMonitoring';
import { ASSIGN_MEDREC_TO_RPM_REPORT, GET_ACCOUNT_NOTES_BY_FILTER, GET_ACCOUNT_PROVIDERS } from '@/graphql/remotePatientMonitoring';
import type { GetEncountersResponse, GetTasksResponse } from '@/graphql/taskEncounter';
import { GET_ENCOUNTERS, GET_TASKS } from '@/graphql/taskEncounter';
import type { AccountCareEventsResponse } from '@/graphql/transitionalCareManagement';
import { GET_ACCOUNT_CARE_EVENTS } from '@/graphql/transitionalCareManagement';
import useMessages from '@/hooks/useMessages';
import useToken from '@/hooks/useToken';
import PatientInfoTabs from '@/routes/PatientDetails/PatientInfoTabs/patientInfoTabs';
import TaskDrawer from '@/routes/PatientDetails/TaskDrawer';
import AssignedProvidersDrawer from '@/routes/Providers/AssignedProvidersDrawer';
import { getAccountDetails } from '@/services/account';
import { color } from '@/styles/assets/colors';
import { Role } from '@/types/admin';
import type { MessageTemplateItem } from '@/types/chat';
import { UserTypes } from '@/types/chat';
import type { TruentityDateInput } from '@/types/date';
import type { AccountCareEvent, MedrecSnapshot } from '@/types/dischargePatient';
import type { ActivityLogType, EncounterType, TaskType } from '@/types/graphql';
import type { Medication } from '@/types/medication';
import { getAccountUserFullName } from '@/util/account';
import { groupBy } from '@/util/array';
import { stringAvatar } from '@/util/avatar';
import { getDateDifferenceFromNow } from '@/util/date';
import { isProd } from '@/util/environment';
import { formatDate, formatDateTimeIgnoreTZ, formatPhoneNumber } from '@/util/format';
import { isCurrent, isImported, isVerified } from '@/util/medications';
import { dateCompare, localeCompare } from '@/util/sort';
import { isStringDefined } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import { useProviderStore } from '@/zustand/ProviderStore';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { mdiLeaf, mdiPrescription, mdiSortCalendarAscending } from '@mdi/js';
import Icon from '@mdi/react';
import AddIcon from '@mui/icons-material/Add';
import AllergyIcon from '@mui/icons-material/AirOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EnergyIcon from '@mui/icons-material/DirectionsRunOutlined';
import VitalsIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ImmunizationIcon from '@mui/icons-material/HealingOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MedicationIcon from '@mui/icons-material/MedicationOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SearchIcon from '@mui/icons-material/Search';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
  Alert,
  Avatar,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  TextField
} from '@mui/material';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import type { RelyingPartyAdminData } from '../Administration';
import type { PatientsData } from '../Patients/patients';
import DischargeSummary from './DischargeReports/Summary';
import EncounterDetails from './Encounters/EncounterDetails';
import MedicationDetails from './MedicationDetails';
import MedicationHistory from './medicationHistory';
import MedicationsRoute from './medications-route';
import { MedicationsMenuHeader } from './MedicationsMenuHeader';
import MedRecHistory from './medRecHistory';
import PatientRequestHistory from './patientRequestHistory';
import QuickUploadHistory from './quickUploadHistory';
import QuickUploadItems from './quickUploadItems';
import TasksRoute from './tasks-route';
import TCMTaskDetails from './TransitionalCareManagement/TCMTaskDetailed/TcmTaskDetails';
import TriageMedication from './Triage/triageMedicationBoard';

//TODO:  These should be in a Map
const medIcon = () => {
  return <MedicationIcon fontSize="small" />;
};

const allergyIcon = () => {
  return <AllergyIcon fontSize="small" />;
};

const immunizationIcon = () => {
  return <ImmunizationIcon fontSize="small" />;
};

const vitalsIcon = () => {
  return <VitalsIcon fontSize="small" />;
};

const energyIcon = () => {
  return <EnergyIcon fontSize="small" />;
};

const getIcon = (key: string) => {
  switch (key) {
    case 'medications':
      return medIcon;
    case 'immunizations':
      return immunizationIcon;
    case 'allergies':
      return allergyIcon;
    case 'energy':
      return energyIcon;
    case 'vitals':
      return vitalsIcon;
  }
};

const PatientDetailsSection = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const pathnameToArray = pathname.split('/');
  const disableLockCertify =
    pathnameToArray[pathnameToArray.length - 1] === 'new' || pathnameToArray[pathnameToArray.length - 1] === 'edit';

  const { roleType, addNewRecentPatient } = useToken();
  const [patientInfo, setPatientInfo] = useState<PatientsData>({} as PatientsData);
  const { assignedProviders, setAssignedProviders } = useProviderStore();
  const [updateAccountProviderSuccess, setUpdateAccountProviderSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [medicationSelected, setMedicationSelected] = useState<Medication | undefined>();
  const [currentOption, setCurrentOption] = useState<string | null>(null);
  const [s3_key, setS3_key] = useState<string | null>(null);
  const [lastRequestCreated, setLastRequestCreated] = useState<string | null>();
  const [isMessageTemplatesOpen, setIsMessageTemplatesOpen] = useState<boolean>(false);
  const [templateValue, setTemplateValue] = useState<MessageTemplateItem | null>(null);

  const [selectedAdtEvent, setSelectedAdtEvent] = useState<string | null>('');

  const [medications, setMedications] = useState<Medication[]>([]);
  const [pioneerImports, setPioneerImports] = useState<PioneerRxEventTypes[]>([]);
  const [resettingMeds, setResettingMeds] = useState<boolean>(false);

  const [fetchTasksInPatientContextLocally, setFetchTasksInPatientContextLocally] = useState<boolean>(false);

  const [tasks, setTasks] = useState<TaskType[]>([]);
  const [tasksTotalCount, setTasksTotalCount] = useState<number>(0);
  const [encounters, setEncounters] = useState<EncounterType[]>([]);
  const [accountTCMTasks] = useState([]);
  const [accountCareEvents, setAccountCareEvents] = useState<AccountCareEvent[]>([]);
  const [reloadEncountersBool, setReloadEncountersBool] = useState(false);
  const [reloadPatientInfo, setReloadPatientInfo] = useState(false);
  const [tasksEncountersLogs, setTasksEncountersLogs] = useState<ActivityLogType[]>([]);
  const [taskDrawerInfo, setTaskDrawerInfo] = useState<TaskType>({} as TaskType);
  const [isLogViewVisible, setIsLogViewVisible] = useState<boolean>(false);
  const [relyingPartyAdmins, setRelyingPartyAdmins] = useState<RelyingPartyAdminData[]>([]);
  const [medRecSnapshots, setMedRecSnapshots] = useState<MedrecSnapshot[]>([]);
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);
  const { showModal } = useModal();
  const [medicationNavItems, setMedicationNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const [importsNavItems, setImportsNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const [medRecHistoryNavItems, setMedRecHistoryNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const [encounterNavItems, setEncounterNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const [accountCareEventsNavItems, setAccountCareEventsNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const [accountADTEventsNavItems, setAccountADTEventsNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const [healthAppNavItems, setHealthAppNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const { setContent } = useContext(SideMenuContext);
  const [currentMedicationCount, setCurrentMedicationCount] = useState(0);

  const [getInfo, { data: patientData, loading: loadingPatientData, refetch: refetchPatientInfo }] = useLazyQuery<GetPatientDetailResponse>(
    GET_PATIENT_DETAIL,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const [assignMedRecSnapshot] = useMutation(ASSIGN_MEDREC_TO_RPM_REPORT);

  const [getAccountNotesByFilter, { data: accountNotesDataByFilter }] =
    useLazyQuery<GetAccountNotesByFilterResponse>(GET_ACCOUNT_NOTES_BY_FILTER);

  const { setAccountNotes, queryNotes, setQueryNotes, isAccountChanged, setIsAccountChanged, setAccountFollowUps } = useAccountStore();

  useEffect(() => {
    if (patientInfo?.user && patientInfo?.truentityId) {
      addNewRecentPatient({
        truentityId: patientInfo.truentityId,
        name: getAccountUserFullName(patientInfo.user),
        rpmStatus: patientInfo.rpmStatus,
        accountsMonitoringDevices: patientInfo?.accountsMonitoringDevices
      });
    }
  }, [patientInfo]);

  useEffect(() => {
    //TODO:  need a better way to make sure states update
    if (queryNotes) {
      queryNotes.updated = undefined;
    }
    getAccountNotesByFilter({
      variables: {
        truentityId: id,
        filterOptions: queryNotes?.filter || {}
      }
    }).catch(error => console.error(error));
  }, [queryNotes]);

  //FIXME sort filter
  useEffect(() => {
    if (accountNotesDataByFilter) {
      setAccountNotes(accountNotesDataByFilter.getAccountNotesByFilter);
    }
  }, [accountNotesDataByFilter]);

  const [getEncountersAfterReloaded, { data: encountersAfterReloadData }] = useLazyQuery<GetEncountersResponse>(GET_ENCOUNTERS);
  // const [
  //   getAccountProvidersDataQuery,
  //   { data: accountProvidersData, called: accountProvidersCalled, loading: accountProvidersDataLoading }
  // ] = useLazyQuery<GetAccountProvidersResponse>(GET_ACCOUNT_PROVIDERS, { fetchPolicy: 'cache-and-network' });

  const {
    loading: accountProvidersDataLoading,
    data: accountProvidersData,
    called: accountProvidersCalled,
    refetch: accountProvidersRefetch
  } = useQuery<AccountProvidersResponse>(GET_ACCOUNT_PROVIDERS, {
    variables: {
      truentityId: id,
      pageSize: DEFAULT_PAGE_SIZE,
      pageNum: 1
    },
    notifyOnNetworkStatusChange: true
  });

  const [resetMedications] = useMutation<ResetAllscriptsMedicationsResponse>(RESET_MEDICATIONS);

  const {
    isMessageDrawerOpen,
    selectedContactNumber,
    setIsMessageDrawerOpen,
    lastMessage,
    messagesByDate,
    sendMessage,
    patient,
    currentUserContactNumbers,
    userContactNumbersError,
    handleContactNumberSelect
  } = useMessages();

  const {
    loading: relyingPartyAdminDataLoading,
    data: relyingPartyAdminData,
    called: relyingPartyAdminDataCalled
  } = useQuery<GetRelyingPartyAdminsResponse>(GET_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [getTasks, { data: tasksDataResponse, refetch: refetchTasks }] = useLazyQuery<GetTasksResponse>(GET_TASKS);

  const { data: accountCareEventsData, refetch: accountCareEventsRefetch } = useQuery<AccountCareEventsResponse>(GET_ACCOUNT_CARE_EVENTS, {
    variables: { truentityId: id, pageNum: 1, pageSize: DEFAULT_PAGE_SIZE }
  });

  const { data: encountersData } = useQuery<GetEncountersResponse>(GET_ENCOUNTERS, {
    variables: {
      truentityId: id,
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  const {
    data: medicationsData,
    loading: medicationLoading,
    refetch: reFetchMedications
  } = useQuery<GetMedicationsResponse>(GET_MEDICATIONS, {
    variables: {
      truentityId: id,
      filterOptions: { includeAttributes: true, includeAttachments: false, includeImages: false },
      pageNum: 1,
      pageSize: HIGH_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const { data: pioneerEventsData, loading: pioneerEventsDataLoading } = useQuery<GetAllPioneerEventsResponse>(GET_ALL_PIONEER_RX_EVENTS, {
    variables: {
      truentityId: id
    }
  });

  const { data: medRecSnapshotsData, refetch: medRecRefetch } = useQuery<GetMedrecSnapshotsResponse>(GET_MED_REC_SNAPSHOTS, {
    variables: {
      truentityId: id
    }
  });

  const renderEncounterNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    const sortedEncounters = [...encounters].sort(localeCompare('calledOn', true));
    const groupedByDate = groupBy(sortedEncounters, m => formatDateTimeIgnoreTZ(m.createdAt));

    const entries = Object.entries(groupedByDate);

    return entries.map(([, children]) => {
      return {
        labelText: children[0].calledOn,
        id: children[0].id
      } as NavigationSidebarMenuItem;
    });
  }, [encounters]);

  const handleMedicationSelected = useCallback(
    (medicationSelected: Medication, edit = false) => {
      if (id && medicationSelected?.id) {
        const type = edit ? 'edit' : 'view';
        navigate(`/patients/${id}/details/medications/${type}/${medicationSelected.id}`, {
          state: {
            returnUrl: location.pathname
          }
        });
      }
    },
    [id, navigate]
  );

  const goToADTEvent = useCallback(
    (eventId: string) => {
      if (id && eventId) {
        navigate(`/patients/${id}/details/adt-events/view/${eventId}`);
      }
    },
    [id, navigate]
  );

  const goToADTEventDetails = useCallback(
    (eventId: string) => {
      if (id && eventId) {
        navigate(`/patients/${id}/details/adt-events/event-details/view/${eventId}`);
      }
    },
    [id, navigate]
  );

  const handlePioneerEventSelected = useCallback(
    (pioneerEventId: PioneerRxEventTypes) => {
      if (id && pioneerEventId?.id) {
        navigate(`/patients/${id}/details/imports/view/${pioneerEventId.id}`, {
          state: {
            returnUrl: location.pathname
          }
        });
      }
    },
    [id, navigate]
  );

  const generateNavItem = (
    item: AccountCareEvent,
    labelTextPrefix: string,
    onClick: () => void,
    labelIcon?: () => JSX.Element,
    disabled?: boolean,
    formatDateFn?: (date: Date | undefined) => string
  ): NavigationSidebarMenuItem & { dischargeDate: Date } => {
    const formattedDate = formatDateFn ? formatDateFn(item.dischargeDate) : item.dischargeDate?.toString() || 'Unknown';

    return {
      labelText: `${labelTextPrefix}${formattedDate}`,
      labelIcon,
      onClick,
      disabled: disabled || item.dischargeDate === null,
      dischargeDate: item?.dischargeDate
    };
  };

  const renderAccountCareEventsNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    return accountCareEvents
      .map((item: AccountCareEvent) => generateNavItem(item, 'Discharged ', () => goToADTEvent(item.id)))
      .sort(dateCompare('dischargeDate', false));
  }, [accountCareEvents, goToADTEvent]);

  const renderADTEventsNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    const IconInstance = () => <RadioButtonUncheckedIcon fontSize="small" />;
    const formatDateFn = (date: Date | undefined) => formatDate(date, 'MMM, DD YYYY') || 'Unknown';

    return accountCareEvents
      .map((item: AccountCareEvent) => generateNavItem(item, '', () => goToADTEventDetails(item.id), IconInstance, undefined, formatDateFn))
      .sort(dateCompare('dischargeDate', false));
  }, [accountCareEvents, goToADTEvent]);

  const renderPioneerRxEventNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    const sortedPioneerEvents = [...pioneerImports].sort(dateCompare('displayName', false));
    const groupedByName = groupBy(sortedPioneerEvents, m => m.displayName || '');
    const entries = Object.entries(groupedByName);

    const pioneerEvents = entries.map(([displayName, children]) => {
      return {
        labelText: displayName,
        labelInfo: children.length.toString(),
        items: children
          .map((item: PioneerRxEventTypes) => {
            let sortByDate: TruentityDateInput;
            const displayName = moment(item.createdAt).format('h:mm A').toString();

            const icon = mdiSortCalendarAscending;
            const IconInstance = () => <Icon path={icon} size={0.8} />;
            return {
              labelText: displayName,
              labelIcon: IconInstance,
              color: isVerified(item) ? 'success.main' : 'error.main',
              onClick: () => handlePioneerEventSelected(item),
              sortByDate
            } as NavigationSidebarMenuItem & {
              sortByDate: TruentityDateInput;
            };
          })
          .sort(dateCompare('sortByDate', false))
      };
    });

    return pioneerEvents;
  }, [handlePioneerEventSelected, pioneerImports]);

  const renderMedicationNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    // Now (May 2022), after the grouping of medications has been done, we can show all meds in the nav items
    // Keeping this commented here, in case we ever want to go back to this in the future.
    // const sortedMeds = [...medications].filter(v => isCurrent(v)).sort(localeCompare('displayName', false))

    const sortedMeds = [...medications].sort(localeCompare('displayName', false));
    const groupedByName = groupBy(sortedMeds, m => m.displayName || '');
    const entries = Object.entries(groupedByName);

    return entries.map(([displayName, children]) => {
      const allScriptsLabel = children.length > 0 ? (children[0].source === 'Imported:Allscripts' ? '(A)' : '') : '';

      return {
        labelText: `${displayName}`,
        labelInfo: children.length.toString(),
        additionalLabelText: allScriptsLabel,
        items: children
          .map((item: Medication) => {
            let sortByDate: TruentityDateInput;
            let displayName = item.displayName;

            if (true === item.isSupplement) {
              displayName = `Added On ${formatDate(item.createdAt)}`;
              sortByDate = item.createdAt;
            } else if (true === isStringDefined(item.lastFillDateAt)) {
              displayName = `Filled On ${formatDate(item.lastFillDateAt)}`;
              sortByDate = item.lastFillDateAt;
            } else if (true === isStringDefined(item.prescriptionWrittenDateAt)) {
              displayName = `Prescribed On ${formatDate(item.prescriptionWrittenDateAt)}`;
              sortByDate = item.prescriptionWrittenDateAt;
            } else if (true === isStringDefined(item.soldDateAt)) {
              displayName = `Sold On ${formatDate(item.soldDateAt)}`;
              sortByDate = item.soldDateAt;
            } else if (item.createdAt !== null) {
              displayName = `Added On ${formatDate(item.createdAt)}`;
              sortByDate = item.createdAt;
            }

            if (isImported(item)) {
              displayName += ' *';
            }

            return {
              labelText: displayName,
              labelIcon: () => <Icon path={item.isSupplement ? mdiLeaf : mdiPrescription} size={0.8} />,
              color: isVerified(item) ? 'success.light' : color.orangeDark,
              onClick: (e: Partial<CustomEvent>) => handleMedicationSelected(item, e?.detail > 1),
              sortByDate
            } as NavigationSidebarMenuItem & {
              sortByDate: TruentityDateInput;
            };
          })
          .sort(dateCompare('sortByDate', false))
      };
    });
  }, [handleMedicationSelected, medications]);

  const renderMedRecNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    const sortedMedRecSnapshots = [...medRecSnapshots].sort(localeCompare('createdAt', true));
    const groupedByDate = groupBy(sortedMedRecSnapshots, m => formatDate(m.createdAt));
    const entries = Object.entries(groupedByDate);

    const Icon = () => {
      return <VerifiedUserIcon fontSize="small" />;
    };

    return entries.map(([date, children]) => {
      return {
        labelText: date,
        labelInfo: children.length.toString(),
        items: children.map(item => ({
          labelText: item.name,
          labelIcon: Icon,
          onClick: () => navigate(`medications/med-rec/${item.id}`)
        }))
      } as NavigationSidebarMenuItem;
    });
  }, [medRecSnapshots, navigate]);

  const renderHealthAppNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    const menuItems = {
      medications: 'Medications',
      allergies: 'Allergies',
      immunizations: 'Immunizations',
      energy: 'Energy Profile',
      vitals: 'Vitals'
    };
    const entries = Object.entries(menuItems);

    return entries.map(([key, value]) => {
      const icon = getIcon(key);
      const url = `shared/health-app/${key}`;

      return {
        labelText: value,
        labelIcon: icon,
        onClick: () => navigate(url)
      };
    });
  }, [navigate]);

  const handleNewMedRec = useCallback(
    (success: boolean, truentityId: string, createdMedRecId = '', openAssignDialog = false) => {
      if (success) {
        enqueueSnackbar('MedRec Snapshot Created', {
          variant: 'success'
        });

        medRecRefetch().catch(err => {
          console.error(err);
          enqueueSnackbar('MedRec Refetch Failed', {
            variant: 'error'
          });
        });

        if (openAssignDialog) {
          showAssignMedRecSnapshotToRpmReportConfirmation(createdMedRecId, truentityId);
        }
      } else {
        enqueueSnackbar('MedRec Snapshot Failed', {
          variant: 'error'
        });
      }
    },
    [enqueueSnackbar, medRecRefetch]
  );

  const assignMedRecSnapshotToRPMReport = useCallback(
    async (medrecSnapshotId: string, id: string | undefined) => {
      return await assignMedRecSnapshot({
        variables: {
          truentityId: id,
          medrecSnapshotId
        }
      });
    },
    [assignMedRecSnapshot]
  );

  const showAssignMedRecSnapshotToRpmReportConfirmation = useCallback(
    (medrecSnapshotId: string, truentityId: string) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Assign Medication Reconciliation Snapshot',
        message: "Do you wish to make this MedRec the primary MedRec in this patient's RPM Setup?",
        onAgree: () => {
          assignMedRecSnapshotToRPMReport(medrecSnapshotId, truentityId).finally(() => {
            modal.hide();
          });
          enqueueSnackbar('MedRec Snapshot Assigned Successfully', {
            variant: 'success'
          });
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [assignMedRecSnapshotToRPMReport, showModal]
  );

  const showModalMedRec = useCallback(() => {
    const verifiedMeds = [...medications].sort(localeCompare('displayName', false)).filter(v => isVerified(v) && isCurrent(v));
    const medRecModal = showModal(MedRecDialog, {
      truentityId: id,
      title: 'Certify Med Rec',
      verifiedMeds,
      handleNewMedRec,
      hideDialog: () => {
        medRecModal.hide();
      }
    });
  }, [handleNewMedRec, id, medications, showModal]);

  const showCreateRequestModal = useCallback(() => {
    const fullName = `${patientInfo?.user.firstName} ${patientInfo?.user.lastName}`;
    const createRequestDialog = showModal(CreateRequestDialog, {
      title: 'Patient Medication Request Info',
      successMessage: `Request has been sent to ${fullName}.`,
      hideDialog: () => {
        createRequestDialog.hide();
      },
      patientId: id,
      patientName: fullName,
      onRequestCreated
    });
  }, [id, patientInfo?.user?.firstName, patientInfo?.user?.lastName, showModal]);

  const handleGoToNewRequest = useCallback(() => {
    showCreateRequestModal();
  }, [showCreateRequestModal]);

  const callRefetch = useCallback(() => {
    return reFetchMedications();
  }, [reFetchMedications]);

  const reloadMeds = useCallback(async () => {
    await callRefetch();
    setResettingMeds(false);
  }, [callRefetch]);

  const callRefetchMedRecSnapshots = async () => {
    await medRecRefetch();
  };

  const onRequestCreated = (newRequestId: string) => {
    setLastRequestCreated(newRequestId);
  };

  const goToNewMedication = useCallback(() => {
    if (id) {
      navigate(`/patients/${id}/details/medications/new`, { state: { returnUrl: window.location.pathname } });
    }
  }, [id, navigate]);

  const callGetTasks = useCallback(
    ({ truentityId, currentPage, taskStatus, selectedOrgId, selectedClientStoreId }) => {
      getTasks({
        variables: {
          truentityId,
          pageNum: currentPage + 1,
          pageSize: DEFAULT_PAGE_SIZE,
          filterOptions: {
            status: taskStatus,
            organization: selectedOrgId?.length > 0 && selectedOrgId !== 'all' ? selectedOrgId : undefined,
            orgStore: selectedClientStoreId?.length > 0 && selectedClientStoreId !== 'all' ? selectedClientStoreId : undefined
          }
        },
        fetchPolicy: 'cache-and-network'
      }).catch(err => console.error(err));
    },
    [getTasks]
  );

  const reloadAllTasks = useCallback(async () => {
    await refetchTasks({
      filterOptions: {
        status: undefined
      }
    });
  }, [refetchTasks]);

  const reloadTasks = useCallback(async () => {
    await refetchTasks();
  }, [refetchTasks]);

  const reloadEncounters = useCallback(async () => {
    setReloadEncountersBool(true);
    await refetchTasks();
  }, [refetchTasks]);

  const goToTriageMedication = useCallback(() => {
    if (id) {
      navigate(`/patients/${id}/details/medications/triage/list`);
    }
  }, [id, navigate]);

  const goDetailsPage = useCallback(() => {
    if (id) {
      navigate(`/patients/${id}/details/`);
    }
  }, [id, navigate]);

  const goToListDetailsPage = useCallback(() => {
    if (id) {
      navigate(`/patients/${id}/details/medications/tasks/list`);
    }
  }, [id, navigate]);

  const goToCurrentEncounterDetailsPage = useCallback(
    (encounterId: string) => {
      if (encounterId && id) {
        navigate(`/patients/${id}/details/medications/encounters/view/${encounterId}`);
      }
    },
    [id, navigate]
  );

  const goToCurrentDischargeSummaryPage = useCallback(
    (adtEventId: string) => {
      if (id && adtEventId) {
        accountCareEventsRefetch()
          .then(data => {
            setAccountCareEvents(data.data.accountCareEvents.accountCareEvents);
          })
          .catch(err => {
            console.log('err', err);
          })
          .finally(() => {
            setTimeout(() => {
              navigate(`/patients/${id}/details/adt-events/view/${adtEventId}`);
            }, 2000);
          });
      }
    },
    [accountCareEventsRefetch, id, navigate]
  );

  const uploadFile = () => {
    if (id) {
      navigate(`/patients/${id}/details/upload-file`);
    }
  };

  const directImport = () => {
    if (id) {
      navigate(`/patients/${id}/details/direct-import`);
    }
  };

  const resetAllMeds = async () => {
    enqueueSnackbar('Resetting Medications', {
      variant: 'info'
    });

    try {
      const response = await resetMedications({
        variables: {
          truentityId: id
        }
      });

      if (response.data?.resetAllscriptsMedication?.status === 'Success') {
        setResettingMeds(true);

        enqueueSnackbar('Resetting Medications successful', {
          variant: 'success'
        });

        await reloadMeds();
        await callRefetchMedRecSnapshots();
        directImport();
        return;
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to reset medication', {
        variant: 'error'
      });
    }
  };

  //TODO:  Horrible workaround. Improve the way we are handling contexts
  const providerValue: PatientDetailContextType = useMemo(
    () =>
      ({
        medications,
        medicationSelected,
        handleGoToNewRequest,
        currentOption,
        setCurrentOption,
        patientId: id,
        lastRequestCreated,
        handleNewMedRec,
        s3_key,
        setS3_key,
        setMedicationSelected,
        reloadMeds,
        goDetailsPage,
        patientInfo,
        setPatientInfo,
        tasks,
        tasksTotalCount,
        encounters,
        reloadEncounters,
        goToListDetailsPage,
        reloadTasks,
        goToCurrentEncounterDetailsPage,
        setTaskDrawerInfo,
        taskDrawerInfo,
        reloadPatientInfo,
        setReloadPatientInfo,
        resettingMeds,
        setResettingMeds,
        accountCareEvents,
        setAccountCareEvents,
        selectedAdtEvent,
        setSelectedAdtEvent,
        goToCurrentDischargeSummaryPage,
        fetchTasksInPatientContextLocally,
        setFetchTasksInPatientContextLocally,
        callGetTasks
      } as unknown as PatientDetailContextType),
    [
      medications,
      medicationSelected,
      handleGoToNewRequest,
      currentOption,
      setCurrentOption,
      id,
      lastRequestCreated,
      handleNewMedRec,
      s3_key,
      setS3_key,
      setMedicationSelected,
      reloadMeds,
      goDetailsPage,
      patientInfo,
      setPatientInfo,
      tasks,
      tasksTotalCount,
      encounters,
      reloadEncounters,
      goToListDetailsPage,
      reloadTasks,
      goToCurrentEncounterDetailsPage,
      setTaskDrawerInfo,
      taskDrawerInfo,
      reloadPatientInfo,
      setReloadPatientInfo,
      resettingMeds,
      setResettingMeds,
      accountCareEvents,
      setAccountCareEvents,
      selectedAdtEvent,
      setSelectedAdtEvent,
      goToCurrentDischargeSummaryPage,
      fetchTasksInPatientContextLocally,
      setFetchTasksInPatientContextLocally,
      callGetTasks
    ]
  );

  useEffect(() => {
    if (accountProvidersCalled && !accountProvidersDataLoading && accountProvidersData?.accountProviders?.accountProviders) {
      setAssignedProviders(accountProvidersData.accountProviders.accountProviders);
    }
  }, [accountProvidersDataLoading, accountProvidersData, accountProvidersCalled]);

  useEffect(() => {
    accountProvidersRefetch();
    setUpdateAccountProviderSuccess(false);
  }, [id, accountProvidersData, updateAccountProviderSuccess]);

  const handleUpdateAccountProviderSuccess = success => {
    setUpdateAccountProviderSuccess(success);
  };

  useEffect(() => {
    if (relyingPartyAdminDataCalled && !relyingPartyAdminDataLoading && relyingPartyAdminData?.relyingPartyAdminsAll?.relyingPartyAdmins) {
      setRelyingPartyAdmins(relyingPartyAdminData.relyingPartyAdminsAll.relyingPartyAdmins);
    }
  }, [relyingPartyAdminDataLoading, relyingPartyAdminData, relyingPartyAdminDataCalled]);

  useEffect(() => {
    const fetchDetails = async () => {
      const info = await getAccountDetails({ truentityId: id });
      if (info) {
        setPatientInfo(info);
        setAccountFollowUps(info?.accountsFollowupReminders);
      }
    };

    fetchDetails().catch(console.error);
  }, [id]);

  useEffect(() => {
    getInfo({
      variables: {
        truentityId: id
      }
    }).catch(console.error);
  }, [getInfo, id]);

  useEffect(() => {
    setQueryNotes({
      updated: new Date()
    });
  }, []);

  useEffect(() => {
    if (reloadPatientInfo) {
      getInfo({
        variables: {
          truentityId: id
        }
      }).catch(console.error);
    }
  }, [getInfo, id, reloadPatientInfo]);

  useEffect(() => {
    if (isAccountChanged) {
      try {
        refetchPatientInfo({
          truentityId: id
        });
      } catch (err) {
        console.error(err);
      } finally {
        setIsAccountChanged(false);
      }
    }
  }, [isAccountChanged]);

  useEffect(() => {
    if (patientData && patientData.accountGet) {
      setPatientInfo(patientData.accountGet);
      setAccountFollowUps(patientData.accountGet?.accountsFollowupRemindersByAdmin);
      setReloadPatientInfo(false);
    }
  }, [patientData]);

  useEffect(() => {
    if (accountTCMTasks) {
      const renderedItems = renderAccountCareEventsNavItems();
      const renderedADTItems = renderADTEventsNavItems();

      setAccountCareEventsNavItems(renderedItems);
      setAccountADTEventsNavItems(renderedADTItems);
    }
  }, [accountCareEvents, accountTCMTasks, renderAccountCareEventsNavItems, renderADTEventsNavItems]);

  useEffect(() => {
    if (medicationsData && medicationsData.accountMedications) {
      const { accountMedications } = medicationsData.accountMedications;
      setMedications(accountMedications.map(med => ({ ...med, displayName: med.displayName?.toUpperCase() })));
    }
  }, [medicationsData]);

  useEffect(() => {
    if (pioneerEventsData && pioneerEventsData.getPioneerrx) {
      const { accounts } = pioneerEventsData.getPioneerrx;
      setPioneerImports(
        accounts.map(account => ({
          ...account,
          displayName: moment(account.createdAt).format('MMM, DD YYYY').toString()
        }))
      );
    }
  }, [pioneerEventsData]);

  useEffect(() => {
    if (tasksDataResponse && tasksDataResponse.tasks) {
      const tasks: TaskType[] = Array.from(tasksDataResponse.tasks.tasks);
      setTasks([...tasks]);
      setTasksTotalCount(tasksDataResponse.tasks.meta.totalCount || 0);
    }
  }, [tasksDataResponse]);

  useEffect(() => {
    if (encountersData && encountersData.encounters) {
      const { encounters } = encountersData.encounters;
      setEncounters(encounters);
    }
  }, [encountersData]);

  useEffect(() => {
    if (accountCareEventsData && accountCareEventsData.accountCareEvents?.accountCareEvents) {
      const { accountCareEvents } = accountCareEventsData.accountCareEvents;
      setAccountCareEvents(accountCareEvents);
    }
  }, [accountCareEventsData]);

  //TODO:  Need to check if there is any need for this
  useEffect(() => {
    if (reloadEncountersBool) {
      getEncountersAfterReloaded({
        variables: {
          truentityId: id,
          pageNum: 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      })
        .then(res => {
          setReloadEncountersBool(false);
          if (res?.data?.encounters?.encounters && res.data.encounters.encounters.length > 0) {
            const sortedEncounters = [...res.data.encounters.encounters].sort(localeCompare('createdAt', true));
            goToCurrentEncounterDetailsPage(sortedEncounters[0].id);
          }
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar('Failed to get encounters after reload', { variant: 'error' });
        });
    }
  }, [enqueueSnackbar, getEncountersAfterReloaded, goToCurrentEncounterDetailsPage, id, reloadEncountersBool]);

  useEffect(() => {
    if (encountersAfterReloadData && encountersAfterReloadData.encounters) {
      setEncounters(encountersAfterReloadData.encounters.encounters);
    }
  }, [encountersAfterReloadData]);

  useEffect(() => {
    if (medications) {
      setCurrentMedicationCount(medications.filter(m => isCurrent(m)).length);
    }
  }, [medications]);

  useEffect(() => {
    if (medRecSnapshotsData && medRecSnapshotsData.medrecSnapshots) {
      const { medrecSnapshots } = medRecSnapshotsData.medrecSnapshots;
      setMedRecSnapshots(medrecSnapshots);
    }
  }, [medRecSnapshotsData]);

  useEffect(() => {
    if (encounters) {
      const renderedItems = renderEncounterNavItems();
      setEncounterNavItems(renderedItems);
    }
  }, [encounters, renderEncounterNavItems]);

  useEffect(() => {
    if (medications) {
      setMedicationNavItems(renderMedicationNavItems());
    }
  }, [medications, renderMedicationNavItems]);

  useEffect(() => {
    if (pioneerImports) {
      setImportsNavItems(renderPioneerRxEventNavItems());
    }
  }, [medications, pioneerImports, renderMedicationNavItems, renderPioneerRxEventNavItems]);

  useEffect(() => {
    if (medRecSnapshots) {
      setMedRecHistoryNavItems(renderMedRecNavItems());
    }
  }, [medRecSnapshots, medicationNavItems, renderMedRecNavItems]);

  useEffect(() => {
    setHealthAppNavItems(renderHealthAppNavItems());
  }, [renderHealthAppNavItems]);

  useEffect(() => {
    if (medicationNavItems !== null && medRecHistoryNavItems !== null && encounterNavItems !== null) {
      setContent(
        <MedSideMenu
          healthAppNavItems={healthAppNavItems}
          medRecHistoryNavItems={medRecHistoryNavItems}
          showModalMedRec={showModalMedRec}
          encounterNavItems={encounterNavItems}
          medicationNavItems={medicationNavItems}
          importsNavItems={importsNavItems}
          goToNewMedication={goToNewMedication}
          goToTriageMedication={goToTriageMedication}
          openMedTree={false}
          accountCareEventsNavItems={accountCareEventsNavItems}
          accountADTEventsNavItems={accountADTEventsNavItems}
          setReloadPatientInfo={setReloadPatientInfo}
          reloadEncounters={reloadEncounters}
          patientInfo={patientInfo}
          loadingNavItems={{
            MEDICATION: medicationLoading ?? false,
            IMPORTS: pioneerEventsDataLoading ?? false
          }}
        />
      );
    }
  }, [
    accountCareEventsNavItems,
    accountADTEventsNavItems,
    encounterNavItems,
    goToNewMedication,
    goToTriageMedication,
    healthAppNavItems,
    importsNavItems,
    medRecHistoryNavItems,
    medicationNavItems,
    medicationLoading,
    pioneerEventsDataLoading,
    reloadEncounters,
    setContent,
    showModalMedRec,
    reloadMeds,
    patientInfo
  ]);

  const closeTaskDrawer = ({ forEdit }: { forEdit: boolean }) => {
    setTaskDrawerInfo({} as TaskType);
    setIsLogViewVisible(false);
    setTasksEncountersLogs([]);

    if (forEdit && id) {
      navigate(`/patients/${id}/details/medications/tasks/edit/${taskDrawerInfo?.type?.toLowerCase()}/${taskDrawerInfo.id}`);
    } else {
      reloadTasks().catch(err => {
        console.error(err);
        enqueueSnackbar('Unable to reload tasks', { variant: 'error' });
      });
      setReloadPatientInfo(true);
      // added this line since we do not need the task id in the url
      // when the task drawer is closed, and we are in the tasks list
      navigate(`/patients/${id}/details/medications/tasks/list`);
    }
  };

  useEffect(() => {
    const filtered = tasks?.find(task => task.id === taskDrawerInfo?.id);

    if (filtered) {
      setTaskDrawerInfo(filtered);
    }
  }, [taskDrawerInfo?.id, tasks]);

  useEffect(() => {
    if (!isMessageTemplatesOpen) {
      setTemplateValue(null);
    }
  }, [isMessageTemplatesOpen]);

  const onNextCallOnHyperlinkClicked = (truentityId: string, isRpmEnrolled: boolean) => {
    setReloadPatientInfo(false);
    showPatientAccountNextCallOnDialog(truentityId, isRpmEnrolled);
  };

  const showPatientAccountNextCallOnDialog = (truentityId: string, isRpmEnrolled: boolean) => {
    const modal = showModal(FollowUpAddEditDialog, {
      title: 'New Follow-Up',
      handleRefetchAndClose: () => {
        setReloadPatientInfo(true);
        modal.hide();
      },
      hideDialog: () => {
        modal.hide();
      },
      truentityId,
      isRpmEnrolled: isRpmEnrolled,
      doNotCall: patientInfo.doNotCall,
      accountAssignments: patientInfo.currentAccountsAssignments
    });
  };

  const onAssignedToChipClicked = () => {
    showAssignAdminModal();
  };

  const showAssignAdminModal = () => {
    const followUpRemindersPresent = patientInfo && patientInfo?.accountsFollowupReminders?.length > 0;

    const modalRef = showModal(AssignRelyingPartyAdminDialog, {
      title: 'Assign to',
      hideDialog: () => {
        setReloadPatientInfo(true);
        modalRef.hide();
      },
      relyingPartyAdmins: relyingPartyAdmins,
      selectedPatients: [patientInfo?.id],
      selectedAdmins: patientInfo?.currentAccountsAssignments?.map(v => v.relyingPartyAdmin),
      isFollowUpPresent: followUpRemindersPresent
    });
  };

  const onSelectMessageTemplateItem = useCallback(
    (templateItem: MessageTemplateItem): void => {
      setTemplateValue(templateItem);
    },
    [setTemplateValue]
  );

  return (
    <>
      <TaskDrawer
        reloadTasks={reloadTasks}
        reloadAllTasks={reloadAllTasks}
        isProviderAdmin={isProviderAdmin}
        closeTaskDrawer={closeTaskDrawer}
        data={taskDrawerInfo}
        setTaskDrawerInfo={setTaskDrawerInfo}
        isLogViewVisible={isLogViewVisible}
        setIsLogViewVisible={setIsLogViewVisible}
        setTasksEncountersLogs={setTasksEncountersLogs}
        tasksEncountersLogs={tasksEncountersLogs}
        relyingPartyAdmins={relyingPartyAdmins}
      />

      <Stack direction="column" spacing={2}>
        <PatientInfoTabs
          patientInfo={patientInfo}
          truentityId={id}
          currentMedsCount={currentMedicationCount}
          onNextCallOnHyperlinkClicked={onNextCallOnHyperlinkClicked}
          onAssignedToChipClicked={onAssignedToChipClicked}
        />

        {pathname.includes('details/medications') && (
          <MedicationsMenuHeader
            isProviderAdmin={isProviderAdmin}
            goToNewMedication={goToNewMedication}
            goToTriageMedication={goToTriageMedication}
            disableLockCertify={disableLockCertify}
            showModalMedRec={showModalMedRec}
            directImport={directImport}
            uploadFile={uploadFile}
            resetAllMeds={resetAllMeds}
            medicationCount={medications?.length}
          />
        )}

        <Paper component={Stack} p={4} sx={{ height: '100%' }}>
          <PatientDetailContext.Provider value={providerValue}>
            <Outlet />
          </PatientDetailContext.Provider>
        </Paper>
      </Stack>

      <MessageDrawer
        readOnly={(currentUserContactNumbers?.relyingPartyAdminContacts?.length ?? 0) === 0}
        inputLabel={'Message'}
        messagesByDate={messagesByDate}
        header={
          <Stack maxWidth={580} flex={1} spacing={1} direction={'row'} justifyContent={'flex-start'} alignItems="center">
            <Avatar
              {...stringAvatar(patient?.patientName ?? '')}
              sx={{ backgroundColor: color.grey400 }}
              aria-label="receipent-header-avatar"
            />
            <Stack spacing={0} direction={'column'} justifyContent={'flex-start'} alignItems="flex-start">
              <H4>{patient?.patientName}</H4>
              <TruentityPhoneNumber value={patient?.patientContactNo} />
            </Stack>
            <Stack flex={1} direction={'row'} justifyContent={'flex-end'} alignItems="center">
              {lastMessage && (
                <ChipsList
                  items={[`Last messaged ${getDateDifferenceFromNow(lastMessage?.timestamp)}`]}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              )}
              <Box
                sx={{
                  marginLeft: 2,
                  borderRadius: '4px'
                }}
              >
                {(currentUserContactNumbers?.relyingPartyAdminContacts?.length ?? 0) > 0 ? (
                  <FormControl color="primary" variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <InputLabel id="user-contactnumber-select-label">From</InputLabel>
                    <Select
                      color="primary"
                      labelId="user-contactnumber-select-label"
                      id="user-contactnumber-select"
                      value={selectedContactNumber}
                      disabled={currentUserContactNumbers?.relyingPartyAdminContacts?.length === 1}
                      onChange={handleContactNumberSelect}
                      IconComponent={KeyboardArrowDownIcon}
                      label="From"
                    >
                      {currentUserContactNumbers?.relyingPartyAdminContacts?.map(({ value }, index) => (
                        <MenuItem
                          sx={{
                            minWidth: 120
                          }}
                          key={index}
                          value={value}
                        >
                          {formatPhoneNumber(value) ?? value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Alert
                    sx={{
                      maxWidth: '100%',
                      whiteSpace: 'pre-wrap',
                      '& .MuiAlert-icon': {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                      }
                    }}
                    severity="warning"
                  >
                    {userContactNumbersError !== null ? (
                      <Box>{userContactNumbersError}</Box>
                    ) : (
                      <Stack direction="column" alignItems="flex-start">
                        <Box>You don't have any contact numbers.</Box>
                        <Box>Please add a contact number to send messages.</Box>
                      </Stack>
                    )}
                  </Alert>
                )}
              </Box>
            </Stack>
          </Stack>
        }
        inputControlsLeft={
          <Stack direction="row">
            {!isMessageTemplatesOpen ? (
              <IconButton
                sx={{
                  background: 'transparent',
                  color: color.primaryMain
                }}
                onClick={() => setIsMessageTemplatesOpen(true)}
                type="button"
                title="MessageTemplates"
              >
                <AddIcon fontSize="medium" />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  background: 'transparent',
                  color: color.primaryMain
                }}
                onClick={() => setIsMessageTemplatesOpen(false)}
                type="reset"
                title="MessageTemplates"
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            )}
          </Stack>
        }
        sidePanel={
          <Slide direction="left" in={isMessageTemplatesOpen} mountOnEnter unmountOnExit>
            <Stack
              sx={{
                display: 'flex',
                width: 500,
                flexDirection: 'column',
                justifyContent: 'stretch',
                alignItems: 'stretch',
                p: 2
              }}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                <H4>Predefined Messages</H4>
                <IconButton onClick={() => setIsMessageTemplatesOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                id="message-templates-search"
                label="Search"
                placeholder="Message"
                variant="outlined"
                fullWidth
                margin="normal"
                size="small"
              />
              <MessageTemplatesList onSelectMessageTemplateItem={onSelectMessageTemplateItem} />
              <Stack width="100%" display="flex" flexDirection="row" justifyContent="stretch" alignItems="stretch" padding={2}>
                <Button fullWidth variant="contained" color="primary" onClick={() => setIsMessageTemplatesOpen(false)}>
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Slide>
        }
        inputMessage={templateValue?.text}
        open={isMessageDrawerOpen}
        onClose={() => setIsMessageDrawerOpen(false)}
        onSubmitCallback={async data => {
          const timestamp = new Date();
          // API call to send message
          await sendMessage({
            message: data.message,
            timestamp: timestamp.getTime(),
            type: UserTypes.user,
            createdAt: timestamp
          });
        }}
      />

      <AssignedProvidersDrawer
        fullName={getAccountUserFullName(patientInfo.user)}
        assignedProviders={assignedProviders}
        onUpdateAccountProvidersSuccess={handleUpdateAccountProviderSuccess}
      />

      <LoadingOverlay active={loadingPatientData} text="Loading Patient details. Please wait..." />

      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'end'}
        sx={{ position: 'fixed', bottom: 50, right: 50, zIndex: theme => theme.zIndex.drawer + 2 }}
      >
        {pathname.includes('medical-consults') && (
          <MedConsultWindow
            lastName={patientInfo && patientInfo.user?.lastName}
            firstName={patientInfo && patientInfo.user?.firstName}
            availableMedRec={medRecSnapshots}
          ></MedConsultWindow>
        )}

        {!isProd() && <CoPilotWindow title={'Truentity Co-Pilot'}></CoPilotWindow>}
      </Stack>
    </>
  );
};

export {
  PatientRequestHistory,
  QuickUploadHistory,
  QuickUploadItems,
  MedicationHistory,
  MedRecHistory,
  TriageMedication,
  MedicationsRoute,
  TasksRoute,
  MedicationDetails,
  EncounterDetails,
  TCMTaskDetails,
  DischargeSummary
};

export default PatientDetailsSection;
